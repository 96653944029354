import { gql, useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Link } from '@faceup/router'
import { Card, ContentLayout, Paper, Switch, TableEnhanced } from '@faceup/ui'
import { Col, Input, Pagination, Row, Select, Space, notification } from '@faceup/ui-base'
import { Country, DEFAULT_COUNTRY, Plan, convertCountryToCountryName } from '@faceup/utils'
import moment, { type MomentInput } from 'moment-timezone'
import { useState } from 'react'
import DotText from '../Components/DotText'
import {
  ChargebeeSubscriptionStatus,
  Institution,
  type InstitutionsTableQuery,
  type InstitutionsTableQueryVariables,
} from '../__generated__/globalTypes'
import { DATE_FORMAT, ROWS_PER_PAGE } from '../constants'
import CreateCompany from './Companies/CreateCompany'

const { Option } = Select

const query = {
  InstitutionsTableQuery: gql`
    query InstitutionsTableQuery(
      $page: Int!
      $name: GraphQLString
      $institutionType: Institution!
      $orders: [OrderByCompanies]
      $plan: [Plan]
      $rowsPerPage: Int!
      $country: Country
      $isVerified: Boolean
      $memberEmail: GraphQLString
    ) {
      companies(
        page: $page
        name: $name
        institutionType: $institutionType
        rowsPerPage: $rowsPerPage
        orders: $orders
        plan: $plan
        isMother: true
        country: $country
        isVerified: $isVerified
        memberEmail: $memberEmail
      ) {
        totalCount
        items {
          id
          parentId
          name
          country
          countOfAllCompanyReports
          registered
          isPendingVerification
          isVerified
          createdAt

          billing {
            id
            subscriptionStatus
            plan
          }
        }
      }
    }
  `,
}

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: '34%',
    render: ([institutionId, name]: [string, string]) => (
      <Link to={routes => routes.institution({ id: institutionId })}>{name}</Link>
    ),
  },
  {
    title: 'Count of reports',
    dataIndex: 'countOfReports',
    width: '12%',
  },
  {
    title: 'Country',
    dataIndex: 'country',
    width: '12%',
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
    width: '15%',
    render: (institutionData: {
      registered: boolean
      isVerified: boolean
      isPendingVerification: boolean
    }) => {
      if (institutionData.registered) {
        return <DotText color='#9649Cb'>Registered</DotText>
      }

      if (institutionData.isPendingVerification) {
        return <DotText color='#ff9b42'>Pending verification</DotText>
      }

      return <DotText color='#ff0035'>Unregistered</DotText>
    },
  },
  {
    title: 'Version',
    dataIndex: 'version',
    width: '11%',
    render: (version: Plan) => {
      if (version === Plan.Free) {
        return <DotText color='#2eb271'>Free</DotText>
      }

      if (version === Plan.Basic) {
        return <DotText color='#9649Cb'>Basic</DotText>
      }

      if (version === Plan.Standard) {
        return <DotText color='#ff9b42'>Standard</DotText>
      }

      if (version === Plan.Premium) {
        return <DotText color='#ff0035'>Premium</DotText>
      }

      return null
    },
  },
  {
    title: 'Payment',
    dataIndex: 'payment',
    width: '7%',
    render: (payment: ChargebeeSubscriptionStatus) => {
      if (payment === ChargebeeSubscriptionStatus.InTrial) {
        return <DotText color='#2eb271'>{payment}</DotText>
      }

      if (payment === ChargebeeSubscriptionStatus.Cancelled) {
        return <DotText color='#9649Cb'>{payment}</DotText>
      }

      if (payment === ChargebeeSubscriptionStatus.Active) {
        return <DotText color='#ff9b42'>{payment}</DotText>
      }

      if (payment === ChargebeeSubscriptionStatus.Future) {
        return <DotText color='#ff0035'>{payment}</DotText>
      }

      return payment
    },
  },
  {
    title: 'Registration date',
    dataIndex: 'createdAt',
    width: '12%',
    render: (createdAt: MomentInput) => createdAt && moment(createdAt).format(DATE_FORMAT),
  },
]

type Props = {
  type: Institution
}

const Institutions = ({ type }: Props) => {
  const [page, setPage] = useState(1)
  const [name, setName] = useState('')
  const [country, setCountry] = useState<Country>()
  const [plan, setPlan] = useState<Plan>()
  const [email, setEmail] = useState('')
  const [waitingForRegistration, setWaitingForRegistration] = useState(false)

  const { loading, data } = useQuery<InstitutionsTableQuery, InstitutionsTableQueryVariables>(
    query.InstitutionsTableQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        page: page - 1,
        name,
        country,

        plan: plan
          ? [plan]
          : waitingForRegistration
            ? [Plan.Free, Plan.Basic, Plan.Standard, Plan.Premium]
            : undefined,
        institutionType: type,
        rowsPerPage: ROWS_PER_PAGE,
        isVerified: waitingForRegistration ? !waitingForRegistration : undefined,
        memberEmail: email ? email : undefined,
      },
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL ERROR',
          description: error.message,
        })
      },
    }
  )

  const institutions = data?.companies?.items?.map(item => ({
    key: item?.id,
    name: [item?.id, item?.name],
    countOfReports: item?.countOfAllCompanyReports,
    version: item?.billing?.plan,
    country: convertCountryToCountryName(item?.country ?? DEFAULT_COUNTRY, 'en'),
    plan: {
      registered: item?.registered,
      isVerified: item?.isVerified,
      isPendingVerification: item?.isPendingVerification,
    },
    payment: item?.billing?.subscriptionStatus,
    createdAt: item?.createdAt,
  }))

  return (
    <ContentLayout
      header={
        <ContentLayout.Header
          title={type === Institution.Company ? 'Companies' : 'Schools'}
          secondaryContent={<CreateCompany institutionType={type} />}
        />
      }
    >
      <Space direction='vertical' size='large' style={{ width: '100%' }}>
        <Paper outline='dark'>
          <Row gutter={16} style={{ padding: '24px' }}>
            <Col xs={24} sm={12} md={5}>
              <InputLabel>Name</InputLabel>
              <Input placeholder='Search' onChange={({ target: { value } }) => setName(value)} />
            </Col>
            <Col xs={24} sm={12} md={5}>
              <InputLabel>Plan</InputLabel>
              <Select style={{ width: '100%' }} allowClear placeholder='Plan' onChange={setPlan}>
                {Object.values(Plan).map(plan => (
                  <Option key={plan ?? ''} value={plan ?? ''}>
                    {plan}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <InputLabel>Country</InputLabel>
              <Select
                style={{ width: '100%' }}
                allowClear
                placeholder='Country'
                onChange={setCountry}
                showSearch
              >
                {Object.values(Country).map(country => (
                  <Option key={country ?? ''} value={country ?? ''}>
                    {convertCountryToCountryName(country ?? DEFAULT_COUNTRY, 'en')}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={5}>
              <InputLabel>Email</InputLabel>
              <br />
              <Input placeholder='Email' onChange={({ target: { value } }) => setEmail(value)} />
            </Col>
            <Col xs={24} sm={12} md={4}>
              <InputLabel>Waiting for registration</InputLabel>
              <br />
              <Switch onChange={() => setWaitingForRegistration(!waitingForRegistration)} />
            </Col>
          </Row>
        </Paper>
        <Card hasNoPadding>
          <TableEnhanced
            loading={loading}
            columns={columns}
            dataSource={institutions}
            pagination={false}
            style={{ minHeight: 300 }}
            scroll={{ x: 'max-content' }}
          />
          <Pagination
            pageSize={ROWS_PER_PAGE}
            current={page}
            total={data?.companies?.totalCount}
            onChange={page => setPage(page)}
            showSizeChanger={false}
          />
        </Card>
      </Space>
    </ContentLayout>
  )
}

const InputLabel = styled.span`
  display: inline-block;
  margin: 10px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #232323;
`

export default Institutions
