import { gql } from '@apollo/client'
import { type ReactNode, createContext, useContext } from 'react'
import { FuAdminRole, type FuViewerProvider_admin } from '../__generated__/globalTypes'

export const fragments = {
  FuViewerProvider_admin: gql`
    fragment FuViewerProvider_admin on FuAdmin {
      id
      name
      role
      companyCountries
      schoolCountries
    }
  `,
}

type AdminAccessRightsContextProps = {
  isAllowedInstitutionEdit: boolean
  isAllowedInstitutionLink: boolean
  isAllowedCreatePaymentUrl: boolean
  isAllowedPartnerEdit: boolean
  isAllowedDemoCompany: boolean
  isAllowedApproveOrRejectSchool: boolean
  isAllowedInstitutionMemberActions: boolean
}

type AdminAccessRightsProps = { children: ReactNode; viewer: FuViewerProvider_admin | null }

export const AdminAccessRightsContext = createContext<AdminAccessRightsContextProps>({
  isAllowedInstitutionLink: false,
  isAllowedInstitutionEdit: false,
  isAllowedCreatePaymentUrl: false,
  isAllowedPartnerEdit: false,
  isAllowedDemoCompany: false,
  isAllowedApproveOrRejectSchool: false,
  isAllowedInstitutionMemberActions: false,
})

const AdminAccessRights = ({ children, viewer }: AdminAccessRightsProps) => {
  const isManager = viewer?.role === FuAdminRole.Manager
  const isAdmin = viewer?.role === FuAdminRole.Admin
  const isPsychologist = viewer?.role === FuAdminRole.Psychologist

  const permissions: AdminAccessRightsContextProps = {
    isAllowedInstitutionEdit: isAdmin,
    isAllowedPartnerEdit: isAdmin,
    isAllowedCreatePaymentUrl: isAdmin || isManager,
    isAllowedInstitutionLink: isAdmin || isManager,
    isAllowedDemoCompany: isAdmin || isManager,
    isAllowedApproveOrRejectSchool: isAdmin || isPsychologist,
    isAllowedInstitutionMemberActions: isAdmin,
  }

  return (
    <AdminAccessRightsContext.Provider value={permissions}>
      {children}
    </AdminAccessRightsContext.Provider>
  )
}

export const useAdminAccessRights = () => useContext(AdminAccessRightsContext)

export default AdminAccessRights
