import { gql, useQuery } from '@apollo/client'
import { Card, TableEnhanced, UserAvatar } from '@faceup/ui'
import { Space, Tag, Typography, notification } from '@faceup/ui-base'
import moment from 'moment-timezone'
import {
  InvitationStatus,
  type PartnerMembersQuery,
  type PartnerMembersQueryVariables,
} from '../__generated__/globalTypes'
import { DATE_FORMAT } from '../constants'

const { Text } = Typography

const query = {
  PartnerMembersQuery: gql`
    query PartnerMembersQuery($partnerId: PartnerInstitutionGlobalId!) {
      partner(partnerId: $partnerId) {
        id
        members {
          id
          name
          email
          phone
          profileImageUrl
          invitation
          lastLogin
        }
      }
    }
  `,
}

type Props = {
  institutionId: string | null
}

const PartnersTable = ({ institutionId }: Props) => {
  const { data, loading } = useQuery<PartnerMembersQuery, PartnerMembersQueryVariables>(
    query.PartnerMembersQuery,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL Error',
          description: error.message,
        })
      },
      fetchPolicy: 'cache-and-network',
      variables: {
        partnerId: institutionId ?? '',
      },
    }
  )

  const institution = data?.partner
  const members = institution?.members ?? []

  return (
    <Card title='Members' hasNoPadding>
      <TableEnhanced
        loading={loading}
        rowKey='id'
        dataSource={members}
        locale={{ emptyText: ' ' }}
        scroll={{ x: 'max-content' }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '60%',
            render: (name, member) => (
              <Space align='center'>
                <UserAvatar user={member} style={{ marginRight: 10 }} />
                <Space direction='vertical' size={0}>
                  <Text style={{ fontSize: 14 }}>{name || member.email}</Text>
                  <Text type='secondary' style={{ fontSize: 13 }}>
                    {member.email} {member.phone && <>({member.phone})</>}
                  </Text>
                </Space>
              </Space>
            ),
          },
          {
            title: 'Status',
            dataIndex: 'invitation',
            key: 'invitation',
            width: '20%',
            render: invitation => (
              <>
                {invitation === InvitationStatus.Active && <Tag color='#217F50'>Active</Tag>}
                {invitation === InvitationStatus.Expired && <Tag color='#B60024'>Expired</Tag>}
                {invitation === InvitationStatus.Sent && <Tag color='#E66B00'>Sent</Tag>}
              </>
            ),
          },
          {
            title: 'Last login',
            dataIndex: 'lastLogin',
            key: 'lastLogin',
            width: '20%',
            render: lastLogin => (lastLogin ? moment(lastLogin).format(DATE_FORMAT) : ''),
          },
        ]}
        pagination={false}
      />
    </Card>
  )
}

export default PartnersTable
