import { Rating } from '@faceup/utils';

/** THIS FILE IS AUTO-GENERATED **/
/** DO NOT EDIT **/


export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Allowed characters in user name */
  AllowedUserName: { input: string; output: string; }
  /** global id of Attachment */
  AttachmentGlobalId: { input: string; output: string; }
  BCP47Language: { input: string; output: string; }
  Base64UrlSafe: { input: string; output: string; }
  Color: { input: string; output: string; }
  /** global id of CompanyConfig */
  CompanyConfigGlobalId: { input: string; output: string; }
  /** global id of Company */
  CompanyGlobalId: { input: string; output: string; }
  /** global id of CompanyReportCategory */
  CompanyReportCategoryGlobalId: { input: string; output: string; }
  /** global id of CompanyReportFollowUpComment */
  CompanyReportFollowUpCommentGlobalId: { input: string; output: string; }
  /** global id of CompanyReport */
  CompanyReportGlobalId: { input: string; output: string; }
  /** global id of CompanyReportInternalComment */
  CompanyReportInternalCommentGlobalId: { input: string; output: string; }
  /** global id of CompanyReportLabel */
  CompanyReportLabelGlobalId: { input: string; output: string; }
  /** global id of CompanyService */
  CompanyServiceGlobalId: { input: string; output: string; }
  DateTime: { input: string; output: string; }
  Email: { input: string; output: string; }
  EncryptionVersion: { input: number; output: number; }
  /** global id of ExternalWebhook */
  ExternalWebhookGlobalId: { input: string; output: string; }
  /** global id of FormItem */
  FormItemGlobalId: { input: string; output: string; }
  /** global id of FormItemOption */
  FormItemOptionGlobalId: { input: string; output: string; }
  GraphQLDeadlinesInterval: { input: number; output: number; }
  GraphQLDeleteReportsMonthInterval: { input: number; output: number; }
  GraphQLInstitutionPassword: { input: string; output: string; }
  GraphQLLongStringLength: { input: number; output: number; }
  GraphQLMediumString: { input: string; output: string; }
  GraphQLOpaqueShortId: { input: string; output: string; }
  GraphQLRating: { input: Rating; output: Rating; }
  GraphQLShortString: { input: string; output: string; }
  GraphQLString: { input: string; output: string; }
  GraphQLText: { input: string; output: string; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: { [key: string]: any }; output: { [key: string]: any }; }
  /** global id of Member */
  MemberGlobalId: { input: string; output: string; }
  /** global id of MemberNotification */
  MemberNotificationGlobalId: { input: string; output: string; }
  /** global id of Page */
  PageGlobalId: { input: string; output: string; }
  /** global id of PartnerInstitution */
  PartnerInstitutionGlobalId: { input: string; output: string; }
  Phone: { input: string; output: string; }
  /** global id of PublicCompany */
  PublicCompanyGlobalId: { input: string; output: string; }
  /** global id of PublicReportSource */
  PublicReportSourceGlobalId: { input: string; output: string; }
  /** global id of ReportSource */
  ReportSourceGlobalId: { input: string; output: string; }
  UUID: { input: string; output: string; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: File | Blob; output: File | Blob; }
  Url: { input: string; output: string; }
  /** global id of Webhook */
  WebhookGlobalId: { input: string; output: string; }
  WorkingHour: { input: string; output: string; }
};

export enum AccountType {
  Owner = 'Owner',
  Regular = 'Regular'
}

export type AddFormItemToCategoryInput = {
  categoryId: Scalars['CompanyReportCategoryGlobalId']['input'];
  formItemId: Scalars['FormItemGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type AddPartnerUserInput = {
  email: Scalars['Email']['input'];
  partnerId: Scalars['PartnerInstitutionGlobalId']['input'];
};

export type AnswerInput = {
  formItemId: Scalars['FormItemGlobalId']['input'];
  /** For select null means redacted */
  values?: InputMaybe<Array<Scalars['GraphQLString']['input']>>;
};

export type ApproveAutomatedHotlineOrderInput = {
  phoneNumber: Scalars['Float']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type ApproveLiveHotlineOrderInput = {
  phoneNumber: Scalars['Float']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type ApprovePartnerRegistrationInput = {
  partnerId: Scalars['PartnerInstitutionGlobalId']['input'];
};

export type ApproveSchoolRegistrationInput = {
  companyId: Scalars['CompanyGlobalId']['input'];
};

export type AvaPlaceWebhookInput = {
  data: Scalars['JSON']['input'];
  token: Scalars['GraphQLString']['input'];
};

export type AwsHandleTranscriptInput = {
  jobName: Scalars['GraphQLString']['input'];
  jobStatus: Scalars['GraphQLString']['input'];
  token: Scalars['GraphQLString']['input'];
};

export type B2CLandingPageFormInput = {
  companyEmail?: InputMaybe<Scalars['GraphQLString']['input']>;
  country: Scalars['GraphQLString']['input'];
  description: Scalars['GraphQLString']['input'];
  email?: InputMaybe<Scalars['GraphQLString']['input']>;
  institutionName: Scalars['GraphQLString']['input'];
  issue: Scalars['GraphQLString']['input'];
};

export enum BillingFrequency {
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Yearly = 'Yearly'
}

export type ChangeFormItemOrderInput = {
  categoryId: Scalars['CompanyReportCategoryGlobalId']['input'];
  formItemId: Scalars['FormItemGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  /**
   * Essentialy, this number is an answer to the question:
   *   "How many form items (not considering me) should be included before me?"
   * Consider example (always type, then current order):
   *   - Category, 0
   *   - OrganizationalUnit, 1
   *   - MoreInfo, 2
   *   - SimpleText, 3
   *
   * If i want to swap more info with simple text, you can either do:
   *   - changeFormItemOrder({ formItemId: <moreInfo.id>, newOrder: 3 }) or
   *   - changeFormItemOrer({ formItemId: <simpleText.id>, newOrder: 2})
   */
  newOrder: Scalars['Int']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type ChangePageOrderInput = {
  language: Language;
  motherId: Scalars['CompanyGlobalId']['input'];
  newPagesOrders: Array<NewPageOrderType>;
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type ChangePartnerFormItemOrderInput = {
  categoryId: Scalars['CompanyReportCategoryGlobalId']['input'];
  formItemId: Scalars['FormItemGlobalId']['input'];
  newOrder: Scalars['Int']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type ChangeSubscriptionInput = {
  billingFrequency: BillingFrequency;
  employees: Scalars['Int']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  plan: Plan;
};

export type ChangeSurveyFormItemOrderInput = {
  formItemId: Scalars['FormItemGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  /**
   * Essentialy, this number is an answer to the question:
   *   "How many form items (not considering me) should be included before me?"
   * Consider example (always type, then current order):
   *   - Category, 0
   *   - OrganizationalUnit, 1
   *   - MoreInfo, 2
   *   - SimpleText, 3
   *
   * If i want to swap more info with simple text, you can either do:
   *   - changeSurveyFormItemOrder({ formItemId: <moreInfo.id>, newOrder: 3 }) or
   *   - changeSurveyFormItemOrder({ formItemId: <simpleText.id>, newOrder: 2})
   */
  newOrder: Scalars['Int']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type ChangeSurveyLanguageInput = {
  channelId: Scalars['ReportSourceGlobalId']['input'];
  language: Language;
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type ChangeTrialSubscriptionInput = {
  billingFrequency: BillingFrequency;
  employees: Scalars['Int']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  plan: Plan;
};

export enum ChannelPageType {
  AfterSend = 'AfterSend',
  BeforeSend = 'BeforeSend',
  FormItems = 'FormItems'
}

export enum ChannelStatus {
  Disabled = 'Disabled',
  Enabled = 'Enabled'
}

export enum ChannelType {
  AutomatedHotline = 'AutomatedHotline',
  LiveHotline = 'LiveHotline',
  ReportingSystem = 'ReportingSystem',
  Survey = 'Survey'
}

export enum ChargebeeSubscriptionStatus {
  Active = 'Active',
  Cancelled = 'Cancelled',
  Future = 'Future',
  InTrial = 'InTrial',
  NonRenewing = 'NonRenewing',
  Paused = 'Paused'
}

export type ChargebeeWebhookInput = {
  data: Scalars['JSON']['input'];
  token: Scalars['GraphQLString']['input'];
};

export type ChatMessageInput = {
  /** attachments stream */
  attachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>;
  ciphertext: Scalars['Base64UrlSafe']['input'];
  nonce: Scalars['Base64UrlSafe']['input'];
  originalCommentId: Scalars['CompanyReportFollowUpCommentGlobalId']['input'];
};

export type CheckCompanyReportDeadlineInput = {
  token: Scalars['GraphQLString']['input'];
};

export type CheckPasswordTokensInput = {
  token: Scalars['GraphQLString']['input'];
};

export type CloseReportInput = {
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export enum ClosingReason {
  Other = 'Other',
  Rejected = 'Rejected',
  Resolved = 'Resolved',
  Spam = 'Spam'
}

export type CollectUnfinishedRegistrationsInput = {
  token: Scalars['GraphQLString']['input'];
  window: UnfinishedRegistrationsWindow;
};

export enum CompanyAuditLogActionName {
  AssignMembers = 'AssignMembers',
  ChangedAccessors = 'ChangedAccessors',
  ChangedCategory = 'ChangedCategory',
  ChangedDeadline = 'ChangedDeadline',
  ChangedDeadlineDone = 'ChangedDeadlineDone',
  ChangedInternalComment = 'ChangedInternalComment',
  ChangedLabel = 'ChangedLabel',
  CreateReport = 'CreateReport',
  DeletedInternalComment = 'DeletedInternalComment',
  EditClosingJustification = 'EditClosingJustification',
  EditFormItemAnswer = 'EditFormItemAnswer',
  EditJustification = 'EditJustification',
  EditPriority = 'EditPriority',
  EditReport = 'EditReport',
  EditStatus = 'EditStatus',
  Export = 'Export',
  ReportChangedCompany = 'ReportChangedCompany',
  RestrictRecipient = 'RestrictRecipient'
}

export type CompanyReportCategoryOrderType = {
  id: Scalars['CompanyReportCategoryGlobalId']['input'];
  order: Scalars['Int']['input'];
};

export type CompanyServiceToAdd = {
  categories: Array<Scalars['GraphQLString']['input']>;
  description: Scalars['GraphQLString']['input'];
  language: Language;
};

export type ConnectUkgProInput = {
  api: Scalars['GraphQLShortString']['input'];
  hostname: Scalars['GraphQLShortString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  password: Scalars['GraphQLShortString']['input'];
  username: Scalars['GraphQLShortString']['input'];
};

export enum ContactIssue {
  BuyMaxVariantBasic = 'BuyMaxVariantBasic',
  BuyMaxVariantFree = 'BuyMaxVariantFree',
  BuyMaxVariantPremium = 'BuyMaxVariantPremium',
  BuyMaxVariantStandard = 'BuyMaxVariantStandard',
  ContactAfterTrial = 'ContactAfterTrial',
  ContactDuringTrial = 'ContactDuringTrial',
  ContactLicenceStartInFuture = 'ContactLicenceStartInFuture',
  ContactViaBilling = 'ContactViaBilling',
  Unknown = 'Unknown',
  Upsell = 'Upsell'
}

export type ContactSalesByEmailInput = {
  issue: ContactIssue;
  message: Scalars['GraphQLString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  note?: InputMaybe<Scalars['GraphQLString']['input']>;
  subject: Scalars['GraphQLString']['input'];
};

export type ContactSalesByPhoneInput = {
  issue: ContactIssue;
  motherId: Scalars['CompanyGlobalId']['input'];
  note?: InputMaybe<Scalars['GraphQLString']['input']>;
  phone: Scalars['GraphQLString']['input'];
};

export enum Country {
  Ad = 'Ad',
  Ae = 'Ae',
  Af = 'Af',
  Ag = 'Ag',
  Ai = 'Ai',
  Al = 'Al',
  Am = 'Am',
  Ao = 'Ao',
  Aq = 'Aq',
  Ar = 'Ar',
  As = 'As',
  At = 'At',
  Au = 'Au',
  Aw = 'Aw',
  Ax = 'Ax',
  Az = 'Az',
  Ba = 'Ba',
  Bb = 'Bb',
  Bd = 'Bd',
  Be = 'Be',
  Bf = 'Bf',
  Bg = 'Bg',
  Bh = 'Bh',
  Bi = 'Bi',
  Bj = 'Bj',
  Bl = 'Bl',
  Bm = 'Bm',
  Bn = 'Bn',
  Bo = 'Bo',
  Bq = 'Bq',
  Br = 'Br',
  Bs = 'Bs',
  Bt = 'Bt',
  Bv = 'Bv',
  Bw = 'Bw',
  By = 'By',
  Bz = 'Bz',
  Ca = 'Ca',
  Cc = 'Cc',
  Cd = 'Cd',
  Cf = 'Cf',
  Cg = 'Cg',
  Ch = 'Ch',
  Ci = 'Ci',
  Ck = 'Ck',
  Cl = 'Cl',
  Cm = 'Cm',
  Cn = 'Cn',
  Co = 'Co',
  Cr = 'Cr',
  Cu = 'Cu',
  Cv = 'Cv',
  Cw = 'Cw',
  Cx = 'Cx',
  Cy = 'Cy',
  Cz = 'Cz',
  De = 'De',
  Dj = 'Dj',
  Dk = 'Dk',
  Dm = 'Dm',
  Do = 'Do',
  Dz = 'Dz',
  Ec = 'Ec',
  Ee = 'Ee',
  Eg = 'Eg',
  Eh = 'Eh',
  Er = 'Er',
  Es = 'Es',
  Et = 'Et',
  Fi = 'Fi',
  Fj = 'Fj',
  Fk = 'Fk',
  Fm = 'Fm',
  Fo = 'Fo',
  Fr = 'Fr',
  Ga = 'Ga',
  Gb = 'Gb',
  Gd = 'Gd',
  Ge = 'Ge',
  Gf = 'Gf',
  Gg = 'Gg',
  Gh = 'Gh',
  Gi = 'Gi',
  Gl = 'Gl',
  Gm = 'Gm',
  Gn = 'Gn',
  Gp = 'Gp',
  Gq = 'Gq',
  Gr = 'Gr',
  Gs = 'Gs',
  Gt = 'Gt',
  Gu = 'Gu',
  Gw = 'Gw',
  Gy = 'Gy',
  Hk = 'Hk',
  Hm = 'Hm',
  Hn = 'Hn',
  Hr = 'Hr',
  Ht = 'Ht',
  Hu = 'Hu',
  Id = 'Id',
  Ie = 'Ie',
  Il = 'Il',
  Im = 'Im',
  In = 'In',
  Io = 'Io',
  Iq = 'Iq',
  Ir = 'Ir',
  Is = 'Is',
  It = 'It',
  Je = 'Je',
  Jm = 'Jm',
  Jo = 'Jo',
  Jp = 'Jp',
  Ke = 'Ke',
  Kg = 'Kg',
  Kh = 'Kh',
  Ki = 'Ki',
  Km = 'Km',
  Kn = 'Kn',
  Kp = 'Kp',
  Kr = 'Kr',
  Kw = 'Kw',
  Ky = 'Ky',
  Kz = 'Kz',
  La = 'La',
  Lb = 'Lb',
  Lc = 'Lc',
  Li = 'Li',
  Lk = 'Lk',
  Lr = 'Lr',
  Ls = 'Ls',
  Lt = 'Lt',
  Lu = 'Lu',
  Lv = 'Lv',
  Ly = 'Ly',
  Ma = 'Ma',
  Mc = 'Mc',
  Md = 'Md',
  Me = 'Me',
  Mf = 'Mf',
  Mg = 'Mg',
  Mh = 'Mh',
  Mk = 'Mk',
  Ml = 'Ml',
  Mm = 'Mm',
  Mn = 'Mn',
  Mo = 'Mo',
  Mp = 'Mp',
  Mq = 'Mq',
  Mr = 'Mr',
  Ms = 'Ms',
  Mt = 'Mt',
  Mu = 'Mu',
  Mv = 'Mv',
  Mw = 'Mw',
  Mx = 'Mx',
  My = 'My',
  Mz = 'Mz',
  Na = 'Na',
  Nc = 'Nc',
  Ne = 'Ne',
  Nf = 'Nf',
  Ng = 'Ng',
  Ni = 'Ni',
  Nl = 'Nl',
  No = 'No',
  Np = 'Np',
  Nr = 'Nr',
  Nu = 'Nu',
  Nz = 'Nz',
  Om = 'Om',
  Pa = 'Pa',
  Pe = 'Pe',
  Pf = 'Pf',
  Pg = 'Pg',
  Ph = 'Ph',
  Pk = 'Pk',
  Pl = 'Pl',
  Pm = 'Pm',
  Pn = 'Pn',
  Pr = 'Pr',
  Ps = 'Ps',
  Pt = 'Pt',
  Pw = 'Pw',
  Py = 'Py',
  Qa = 'Qa',
  Re = 'Re',
  Ro = 'Ro',
  Rs = 'Rs',
  Ru = 'Ru',
  Rw = 'Rw',
  Sa = 'Sa',
  Sb = 'Sb',
  Sc = 'Sc',
  Sd = 'Sd',
  Se = 'Se',
  Sg = 'Sg',
  Sh = 'Sh',
  Si = 'Si',
  Sj = 'Sj',
  Sk = 'Sk',
  Sl = 'Sl',
  Sm = 'Sm',
  Sn = 'Sn',
  So = 'So',
  Sr = 'Sr',
  Ss = 'Ss',
  St = 'St',
  Sv = 'Sv',
  Sx = 'Sx',
  Sy = 'Sy',
  Sz = 'Sz',
  Tc = 'Tc',
  Td = 'Td',
  Tf = 'Tf',
  Tg = 'Tg',
  Th = 'Th',
  Tj = 'Tj',
  Tk = 'Tk',
  Tl = 'Tl',
  Tm = 'Tm',
  Tn = 'Tn',
  To = 'To',
  Tr = 'Tr',
  Tt = 'Tt',
  Tv = 'Tv',
  Tw = 'Tw',
  Tz = 'Tz',
  Ua = 'Ua',
  Ug = 'Ug',
  Um = 'Um',
  Us = 'Us',
  Uy = 'Uy',
  Uz = 'Uz',
  Va = 'Va',
  Vc = 'Vc',
  Ve = 'Ve',
  Vg = 'Vg',
  Vi = 'Vi',
  Vn = 'Vn',
  Vu = 'Vu',
  Wf = 'Wf',
  Ws = 'Ws',
  Ye = 'Ye',
  Yt = 'Yt',
  Za = 'Za',
  Zm = 'Zm',
  Zw = 'Zw'
}

export type CreateAdminRequestInput = {
  email: Scalars['Email']['input'];
  name: Scalars['GraphQLShortString']['input'];
  nonce: Scalars['Base64UrlSafe']['input'];
  passwordPrehash: Scalars['Base64UrlSafe']['input'];
  privateKeyEncrypted: Scalars['Base64UrlSafe']['input'];
  publicKey: Scalars['Base64UrlSafe']['input'];
  salt: Scalars['Base64UrlSafe']['input'];
  token: Scalars['GraphQLString']['input'];
  totpSecret: Scalars['GraphQLString']['input'];
};

export type CreateApiKeyInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLString']['input'];
};

export type CreateAutomatedHotlineOrderInput = {
  country: HotlineCountry;
  defaultLanguage: HotlineLanguage;
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLString']['input'];
  phoneNumberType: HotlinePhoneNumberType;
};

export type CreateCompanyInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLString']['input'];
  parentCompanyId: Scalars['CompanyGlobalId']['input'];
};

export type CreateCompanyReportCategoryInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLShortString']['input'];
};

export type CreateCompanyReportFollowUpCommentInput = {
  /** attachments stream */
  attachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>;
  ciphertext: Scalars['Base64UrlSafe']['input'];
  nonce: Scalars['Base64UrlSafe']['input'];
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type CreateCompanyReportInternalCommentInput = {
  /** attachments stream */
  attachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>;
  ciphertext: Scalars['Base64UrlSafe']['input'];
  mentionedMemberIds: Array<Scalars['UUID']['input']>;
  nonce: Scalars['Base64UrlSafe']['input'];
  parentCommentId?: InputMaybe<Scalars['CompanyReportInternalCommentGlobalId']['input']>;
  recipientKeys: Array<MemberReportKeys>;
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type CreateCompanyReportLabelInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLShortString']['input'];
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type CreateCompanyServiceInput = {
  countries: Array<Country>;
  defaultLanguage: Language;
  institutionType: Institution;
  /** Logo stream */
  logo?: InputMaybe<Scalars['Upload']['input']>;
  notAvailablePartnerIds?: InputMaybe<Array<InputMaybe<Scalars['PartnerInstitutionGlobalId']['input']>>>;
  providerEmail: Scalars['Email']['input'];
  providerName: Scalars['GraphQLShortString']['input'];
  providerUrl: Scalars['Url']['input'];
  translations: Array<InputMaybe<CompanyServiceToAdd>>;
};

export type CreateDemoCompanyInput = {
  country: Country;
  importMockData: Scalars['Boolean']['input'];
  institutionType: Institution;
  /** All members emails (at least one) */
  membersEmails: Array<Scalars['GraphQLString']['input']>;
  name: Scalars['GraphQLString']['input'];
  partnerId?: InputMaybe<Scalars['PartnerInstitutionGlobalId']['input']>;
  requestedPlan: Plan;
  timezone: Scalars['GraphQLString']['input'];
};

export type CreateEmployeesInvolvementInput = {
  caseId: Scalars['UUID']['input'];
  employeeIds: Array<Scalars['UUID']['input']>;
  involvement: EmployeeInvolvement;
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type CreateExportActivityLogItemMutationInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  reportIds: Array<Scalars['CompanyReportGlobalId']['input']>;
};

export type CreateExternalWebhookInput = {
  source: Scalars['GraphQLString']['input'];
  token: Scalars['GraphQLString']['input'];
  type: WebhookType;
  url: Scalars['Url']['input'];
};

export type CreateFormInput = {
  defaultLanguage: Language;
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLString']['input'];
};

export type CreateFormItemInput = {
  addToCategoriesIds: Array<Scalars['CompanyReportCategoryGlobalId']['input']>;
  hint?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
  isRequired: Scalars['Boolean']['input'];
  label: Scalars['GraphQLShortString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
  type: FormItemType;
};

export type CreateInternalMaterialInput = {
  file: Scalars['Upload']['input'];
  generatedDocument: MaterialGenerationType;
  institution: Institution;
  isForSchoolDefaultForm: Scalars['Boolean']['input'];
  isFree: Scalars['Boolean']['input'];
  language: Language;
  name: Scalars['GraphQLString']['input'];
  type: MaterialType;
  withActivePassword: Scalars['Boolean']['input'];
};

export type CreateMemberInput = {
  companyIds: Array<Scalars['CompanyGlobalId']['input']>;
  email: Scalars['Email']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  permissions: Array<PermissionInput>;
};

export type CreateMemberReportInput = {
  answers: Array<AnswerInput>;
  /** Attachments */
  attachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>;
  body: Scalars['Base64UrlSafe']['input'];
  bodyNonce: Scalars['Base64UrlSafe']['input'];
  categoryId: Scalars['CompanyReportCategoryGlobalId']['input'];
  companyId: Scalars['CompanyGlobalId']['input'];
  createdAt: Scalars['DateTime']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  recipientKeys: Array<MemberReportKeys>;
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
  source: ReportSourceType;
  status: ReportStatusType;
};

export type CreateOAuthBambooHrUrlInput = {
  hostname: Scalars['GraphQLShortString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type CreateOAuthSlackUrlInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type CreateOAuthUkgReadyUrlInput = {
  companyId: Scalars['GraphQLShortString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type CreatePageInput = {
  content: Scalars['GraphQLText']['input'];
  icon: Scalars['GraphQLString']['input'];
  language: Language;
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
  title: Scalars['GraphQLShortString']['input'];
};

export type CreatePartnerFormItemInput = {
  addToCategoriesIds: Array<Scalars['CompanyReportCategoryGlobalId']['input']>;
  hint?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
  isRequired: Scalars['Boolean']['input'];
  label: Scalars['GraphQLShortString']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
  type: FormItemType;
};

export type CreatePartnerPortalSessionInput = {
  partnerId: Scalars['PartnerInstitutionGlobalId']['input'];
};

export type CreatePartnerReportCategoryInput = {
  configId: Scalars['CompanyConfigGlobalId']['input'];
  name: Scalars['GraphQLShortString']['input'];
};

export type CreatePaymentUrlInput = {
  allowOfflinePaymentMethods: Scalars['Boolean']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type CreatePortalSessionInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type CreateRedactedReportInput = {
  answers: Array<AnswerInput>;
  attachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>;
  body: Scalars['Base64UrlSafe']['input'];
  bodyNonce: Scalars['Base64UrlSafe']['input'];
  followUpMessages: Array<ChatMessageInput>;
  internalComments: Array<InternalMessageInput>;
  motherId: Scalars['CompanyGlobalId']['input'];
  originalReportId: Scalars['CompanyReportGlobalId']['input'];
  recipientKeys: Array<MemberReportKeys>;
  redactedChatUsers: Array<RedactedUserInput>;
  redactedCommentUserIds: Array<RedactedUserInput>;
  reportClosing?: InputMaybe<ReportClosingType>;
};

export type CreateReportInput = {
  answers: Array<AnswerInput>;
  /** attachments stream */
  attachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>;
  body: Scalars['Base64UrlSafe']['input'];
  bodyNonce: Scalars['Base64UrlSafe']['input'];
  categoryId: Scalars['CompanyReportCategoryGlobalId']['input'];
  companyId: Scalars['PublicCompanyGlobalId']['input'];
  nonce: Scalars['Base64UrlSafe']['input'];
  password?: InputMaybe<Scalars['GraphQLInstitutionPassword']['input']>;
  passwordPrehash: Scalars['GraphQLString']['input'];
  privateKeyEncrypted: Scalars['Base64UrlSafe']['input'];
  publicKey: Scalars['Base64UrlSafe']['input'];
  recipientKeys: Array<MemberReportKeys>;
  reportSourceId: Scalars['PublicReportSourceGlobalId']['input'];
  salt: Scalars['Base64UrlSafe']['input'];
  senderKey: Scalars['Base64UrlSafe']['input'];
};

export type CreateSelectFormItemInput = {
  addToCategoriesIds: Array<Scalars['CompanyReportCategoryGlobalId']['input']>;
  hint?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
  isRequired: Scalars['Boolean']['input'];
  label: Scalars['GraphQLShortString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  options: Array<NewFormItemOptionInput>;
  order?: InputMaybe<Scalars['Int']['input']>;
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
  type: FormItemType;
};

export type CreateSnippetInput = {
  content: Scalars['GraphQLMediumString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLShortString']['input'];
  shortcut: Scalars['GraphQLShortString']['input'];
};

export type CreateSubscriptionInput = {
  billingFrequency: BillingFrequency;
  coupon?: InputMaybe<Scalars['GraphQLString']['input']>;
  employees: Scalars['Int']['input'];
  isPurchase?: InputMaybe<Scalars['Boolean']['input']>;
  motherId: Scalars['CompanyGlobalId']['input'];
  plan: Plan;
};

export type CreateSurveyInput = {
  language: Language;
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLShortString']['input'];
};

export type CreateSurveyItemInput = {
  channelId: Scalars['ReportSourceGlobalId']['input'];
  item: SurveyItemInput;
  motherId: Scalars['CompanyGlobalId']['input'];
  order: Scalars['Int']['input'];
};

export type CreateSurveySelectItemInput = {
  channelId: Scalars['ReportSourceGlobalId']['input'];
  item: SurveyItemInput;
  motherId: Scalars['CompanyGlobalId']['input'];
  options: Array<SurveyItemOptionInput>;
  order: Scalars['Int']['input'];
};

export type CreateSurveySubmissionInput = {
  answers: Array<AnswerInput>;
  reportSourceId: Scalars['PublicReportSourceGlobalId']['input'];
};

export type CreateWebhookInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLString']['input'];
  types: Array<WebhookType>;
  url: Scalars['Url']['input'];
};

export enum Currency {
  ADP = 'ADP',
  AED = 'AED',
  AFA = 'AFA',
  AFN = 'AFN',
  ALK = 'ALK',
  ALL = 'ALL',
  AMD = 'AMD',
  ANG = 'ANG',
  AOA = 'AOA',
  AOK = 'AOK',
  AON = 'AON',
  AOR = 'AOR',
  ARA = 'ARA',
  ARP = 'ARP',
  ARS = 'ARS',
  ARY = 'ARY',
  ATS = 'ATS',
  AUD = 'AUD',
  AWG = 'AWG',
  AYM = 'AYM',
  AZM = 'AZM',
  AZN = 'AZN',
  BAD = 'BAD',
  BAM = 'BAM',
  BBD = 'BBD',
  BDT = 'BDT',
  BEC = 'BEC',
  BEF = 'BEF',
  BEL = 'BEL',
  BGJ = 'BGJ',
  BGK = 'BGK',
  BGL = 'BGL',
  BGN = 'BGN',
  BHD = 'BHD',
  BIF = 'BIF',
  BMD = 'BMD',
  BND = 'BND',
  BOB = 'BOB',
  BOP = 'BOP',
  BOV = 'BOV',
  BRB = 'BRB',
  BRC = 'BRC',
  BRE = 'BRE',
  BRL = 'BRL',
  BRN = 'BRN',
  BRR = 'BRR',
  BSD = 'BSD',
  BTN = 'BTN',
  BUK = 'BUK',
  BWP = 'BWP',
  BYB = 'BYB',
  BYN = 'BYN',
  BYR = 'BYR',
  BZD = 'BZD',
  CAD = 'CAD',
  CDF = 'CDF',
  CHC = 'CHC',
  CHE = 'CHE',
  CHF = 'CHF',
  CHW = 'CHW',
  CLF = 'CLF',
  CLP = 'CLP',
  CNY = 'CNY',
  COP = 'COP',
  COU = 'COU',
  CRC = 'CRC',
  CSD = 'CSD',
  CSJ = 'CSJ',
  CSK = 'CSK',
  CUC = 'CUC',
  CUP = 'CUP',
  CVE = 'CVE',
  CYP = 'CYP',
  CZK = 'CZK',
  DDM = 'DDM',
  DEM = 'DEM',
  DJF = 'DJF',
  DKK = 'DKK',
  DOP = 'DOP',
  DZD = 'DZD',
  ECS = 'ECS',
  ECV = 'ECV',
  EEK = 'EEK',
  EGP = 'EGP',
  ERN = 'ERN',
  ESA = 'ESA',
  ESB = 'ESB',
  ESP = 'ESP',
  ETB = 'ETB',
  EUR = 'EUR',
  FIM = 'FIM',
  FJD = 'FJD',
  FKP = 'FKP',
  FRF = 'FRF',
  GBP = 'GBP',
  GEK = 'GEK',
  GEL = 'GEL',
  GHC = 'GHC',
  GHP = 'GHP',
  GHS = 'GHS',
  GIP = 'GIP',
  GMD = 'GMD',
  GNE = 'GNE',
  GNF = 'GNF',
  GNS = 'GNS',
  GQE = 'GQE',
  GRD = 'GRD',
  GTQ = 'GTQ',
  GWE = 'GWE',
  GWP = 'GWP',
  GYD = 'GYD',
  HKD = 'HKD',
  HNL = 'HNL',
  HRD = 'HRD',
  HRK = 'HRK',
  HTG = 'HTG',
  HUF = 'HUF',
  IDR = 'IDR',
  IEP = 'IEP',
  ILP = 'ILP',
  ILR = 'ILR',
  ILS = 'ILS',
  INR = 'INR',
  IQD = 'IQD',
  IRR = 'IRR',
  ISJ = 'ISJ',
  ISK = 'ISK',
  ITL = 'ITL',
  JMD = 'JMD',
  JOD = 'JOD',
  JPY = 'JPY',
  KES = 'KES',
  KGS = 'KGS',
  KHR = 'KHR',
  KMF = 'KMF',
  KPW = 'KPW',
  KRW = 'KRW',
  KWD = 'KWD',
  KYD = 'KYD',
  KZT = 'KZT',
  LAJ = 'LAJ',
  LAK = 'LAK',
  LBP = 'LBP',
  LKR = 'LKR',
  LRD = 'LRD',
  LSL = 'LSL',
  LSM = 'LSM',
  LTL = 'LTL',
  LTT = 'LTT',
  LUC = 'LUC',
  LUF = 'LUF',
  LUL = 'LUL',
  LVL = 'LVL',
  LVR = 'LVR',
  LYD = 'LYD',
  MAD = 'MAD',
  MDL = 'MDL',
  MGA = 'MGA',
  MGF = 'MGF',
  MKD = 'MKD',
  MLF = 'MLF',
  MMK = 'MMK',
  MNT = 'MNT',
  MOP = 'MOP',
  MRO = 'MRO',
  MTL = 'MTL',
  MTP = 'MTP',
  MUR = 'MUR',
  MVQ = 'MVQ',
  MVR = 'MVR',
  MWK = 'MWK',
  MXN = 'MXN',
  MXP = 'MXP',
  MXV = 'MXV',
  MYR = 'MYR',
  MZE = 'MZE',
  MZM = 'MZM',
  MZN = 'MZN',
  NAD = 'NAD',
  NGN = 'NGN',
  NIC = 'NIC',
  NIO = 'NIO',
  NLG = 'NLG',
  NOK = 'NOK',
  NPR = 'NPR',
  NZD = 'NZD',
  OMR = 'OMR',
  PAB = 'PAB',
  PEH = 'PEH',
  PEI = 'PEI',
  PEN = 'PEN',
  PES = 'PES',
  PGK = 'PGK',
  PHP = 'PHP',
  PKR = 'PKR',
  PLN = 'PLN',
  PLZ = 'PLZ',
  PTE = 'PTE',
  PYG = 'PYG',
  QAR = 'QAR',
  RHD = 'RHD',
  ROK = 'ROK',
  ROL = 'ROL',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  RUR = 'RUR',
  RWF = 'RWF',
  SAR = 'SAR',
  SBD = 'SBD',
  SCR = 'SCR',
  SDD = 'SDD',
  SDG = 'SDG',
  SDP = 'SDP',
  SEK = 'SEK',
  SGD = 'SGD',
  SHP = 'SHP',
  SIT = 'SIT',
  SKK = 'SKK',
  SLL = 'SLL',
  SOS = 'SOS',
  SRD = 'SRD',
  SRG = 'SRG',
  SSP = 'SSP',
  STD = 'STD',
  SUR = 'SUR',
  SVC = 'SVC',
  SYP = 'SYP',
  SZL = 'SZL',
  THB = 'THB',
  TJR = 'TJR',
  TJS = 'TJS',
  TMM = 'TMM',
  TMT = 'TMT',
  TND = 'TND',
  TOP = 'TOP',
  TPE = 'TPE',
  TRL = 'TRL',
  TRY = 'TRY',
  TTD = 'TTD',
  TWD = 'TWD',
  TZS = 'TZS',
  UAH = 'UAH',
  UAK = 'UAK',
  UGS = 'UGS',
  UGW = 'UGW',
  UGX = 'UGX',
  USD = 'USD',
  USN = 'USN',
  USS = 'USS',
  UYI = 'UYI',
  UYN = 'UYN',
  UYP = 'UYP',
  UYU = 'UYU',
  UZS = 'UZS',
  VEB = 'VEB',
  VEF = 'VEF',
  VNC = 'VNC',
  VND = 'VND',
  VUV = 'VUV',
  WST = 'WST',
  XAF = 'XAF',
  XAG = 'XAG',
  XAU = 'XAU',
  XBA = 'XBA',
  XBB = 'XBB',
  XBC = 'XBC',
  XBD = 'XBD',
  XCD = 'XCD',
  XDR = 'XDR',
  XEU = 'XEU',
  XFO = 'XFO',
  XFU = 'XFU',
  XOF = 'XOF',
  XPD = 'XPD',
  XPF = 'XPF',
  XPT = 'XPT',
  XRE = 'XRE',
  XSU = 'XSU',
  XTS = 'XTS',
  XUA = 'XUA',
  XXX = 'XXX',
  YER = 'YER',
  YUD = 'YUD',
  YUM = 'YUM',
  YUN = 'YUN',
  ZAL = 'ZAL',
  ZAR = 'ZAR',
  ZMK = 'ZMK',
  ZMW = 'ZMW',
  ZRN = 'ZRN',
  ZRZ = 'ZRZ',
  ZWC = 'ZWC',
  ZWD = 'ZWD',
  ZWL = 'ZWL',
  ZWN = 'ZWN',
  ZWR = 'ZWR'
}

export enum Day {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type DeleteApiKeyInput = {
  apiKey: Scalars['GraphQLString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type DeleteCompanyInput = {
  companyId: Scalars['CompanyGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  moveReportsToCompanyId: Scalars['CompanyGlobalId']['input'];
};

export type DeleteCompanyOpenIdInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type DeleteCompanyReportCategoryInput = {
  categoryId: Scalars['CompanyReportCategoryGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  moveReportsToCategoryId?: InputMaybe<Scalars['CompanyReportCategoryGlobalId']['input']>;
};

export type DeleteCompanyReportFollowUpCommentInput = {
  commentId: Scalars['CompanyReportFollowUpCommentGlobalId']['input'];
};

export type DeleteCompanyReportInternalCommentInput = {
  commentId: Scalars['CompanyReportInternalCommentGlobalId']['input'];
};

export type DeleteCompanyReportLabelInput = {
  labelId: Scalars['CompanyReportLabelGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type DeleteCompanyServiceInput = {
  serviceId: Scalars['CompanyServiceGlobalId']['input'];
};

export type DeleteEmployeeInvolvementInput = {
  caseId: Scalars['UUID']['input'];
  employeeId: Scalars['UUID']['input'];
  involvement: EmployeeInvolvement;
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type DeleteExternalWebhookInput = {
  token: Scalars['GraphQLString']['input'];
  webhookId: Scalars['ExternalWebhookGlobalId']['input'];
};

export type DeleteFormItemInput = {
  formItemId: Scalars['FormItemGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type DeleteInternalMaterialInput = {
  s3Key: Scalars['GraphQLString']['input'];
};

export type DeleteMemberCreatedReportInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type DeleteMemberInput = {
  memberId: Scalars['UUID']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type DeletePageInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  pageId: Scalars['PageGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type DeletePartnerFormItemInput = {
  formItemId: Scalars['FormItemGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type DeletePartnerInput = {
  partnerId: Scalars['PartnerInstitutionGlobalId']['input'];
};

export type DeletePartnerReportCategoryInput = {
  categoryId: Scalars['CompanyReportCategoryGlobalId']['input'];
  configId: Scalars['CompanyConfigGlobalId']['input'];
};

export type DeletePartnerUserInput = {
  memberId: Scalars['UUID']['input'];
};

export type DeleteReportSourceInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type DeleteSlackIntegrationInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type DeleteSnippetInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  snippetId: Scalars['UUID']['input'];
};

export type DeleteSurveyItemInput = {
  channelId: Scalars['ReportSourceGlobalId']['input'];
  formItemId: Scalars['FormItemGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type DeleteSurveySubmissionInput = {
  channelId: Scalars['ReportSourceGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  submissionId: Scalars['ID']['input'];
};

export type DeleteUserImageInput = {
  deleteImage: Scalars['Boolean']['input'];
};

export type DeleteWebhookInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  webhookId: Scalars['WebhookGlobalId']['input'];
};

export type DisconnectBambooHrInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type DisconnectUkgProInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type DisconnectUkgReadyInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type DisconnectVantaInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type DuplicateSurveyInput = {
  channelId: Scalars['ReportSourceGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  newName: Scalars['GraphQLShortString']['input'];
};

export type EditAutomatedHotlineReportSourceConfigurationInput = {
  categoryIds: Array<Scalars['CompanyReportCategoryGlobalId']['input']>;
  defaultLanguage: HotlineLanguage;
  languages: Array<HotlineLanguage>;
  motherId: Scalars['CompanyGlobalId']['input'];
  organizationalUnitsIds: Array<Scalars['CompanyGlobalId']['input']>;
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
  sendAsAnonymous?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EditAutomatedHotlineReportSourceConfigurationTranslationsInput = {
  afterVoiceRecordingEndMessageTranslation: Scalars['GraphQLMediumString']['input'];
  language: HotlineLanguage;
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
  welcomeMessageTranslation: Scalars['GraphQLMediumString']['input'];
};

export type EditClientInstitutionRegistrationInput = {
  invoicedTo: InvoicedTo;
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type EditCompanyAutomaticDeadlinesInput = {
  automaticDeadlines: Scalars['Boolean']['input'];
  automaticDeadlinesInterval?: InputMaybe<Scalars['GraphQLDeadlinesInterval']['input']>;
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type EditCompanyDeleteReportsInput = {
  deleteReportsMonthInterval?: InputMaybe<Scalars['GraphQLDeleteReportsMonthInterval']['input']>;
  enableDeleteReports: Scalars['Boolean']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type EditCompanyInput = {
  companyId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLShortString']['input'];
};

export type EditCompanyNoteInput = {
  companyId: Scalars['CompanyGlobalId']['input'];
  note: Scalars['GraphQLString']['input'];
};

export type EditCompanyOpenIdInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  openIdClientId: Scalars['GraphQLString']['input'];
  openIdClientSecret: Scalars['GraphQLString']['input'];
  openIdProviderUrl: Scalars['GraphQLString']['input'];
};

export type EditCompanyReportAssignedMembersInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  newAssignedMembers?: InputMaybe<Array<ReportAssignedMember>>;
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type EditCompanyReportCategoryInput = {
  categoryId: Scalars['CompanyReportCategoryGlobalId']['input'];
  language: Language;
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLShortString']['input'];
};

export type EditCompanyReportDeadlineDoneInput = {
  deadlineDone: Scalars['Boolean']['input'];
  institutionId: Scalars['CompanyGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type EditCompanyReportDeadlineInput = {
  institutionId: Scalars['CompanyGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  newDeadline?: InputMaybe<Scalars['DateTime']['input']>;
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type EditCompanyReportInput = {
  answers: Array<AnswerInput>;
  motherId: Scalars['CompanyGlobalId']['input'];
  newBody?: InputMaybe<Scalars['Base64UrlSafe']['input']>;
  newBodyNonce?: InputMaybe<Scalars['Base64UrlSafe']['input']>;
  newCategoryId?: InputMaybe<Scalars['CompanyReportCategoryGlobalId']['input']>;
  newCompanyId?: InputMaybe<Scalars['CompanyGlobalId']['input']>;
  newCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  newSource?: InputMaybe<ReportSourceType>;
  recipients: Array<ReportAssignedMember>;
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type EditCompanyReportInternalCommentInput = {
  /** attachments stream */
  attachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>;
  attachmentsToDelete?: InputMaybe<Array<Scalars['AttachmentGlobalId']['input']>>;
  ciphertext: Scalars['Base64UrlSafe']['input'];
  commentId: Scalars['CompanyReportInternalCommentGlobalId']['input'];
  mentionedMemberIds: Array<Scalars['UUID']['input']>;
  nonce: Scalars['Base64UrlSafe']['input'];
  recipientKeys: Array<MemberReportKeys>;
};

export type EditCompanyReportMemberAccessInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  newAccessMembers?: InputMaybe<Array<ReportAssignedMember>>;
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type EditCompanyReportNameVisibilityInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type EditCompanyReportPriorityInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  newPriority?: InputMaybe<ReportPriority>;
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type EditCompanyReportStatusInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  newStatus: ReportStatusType;
  reportClosing: ReportClosingType;
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type EditCompanyReportViewInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  reportId: Scalars['CompanyReportGlobalId']['input'];
  viewed: Scalars['Boolean']['input'];
};

export type EditCompanyServiceInput = {
  countries?: InputMaybe<Array<InputMaybe<Country>>>;
  defaultLanguage: Language;
  institutionType: Institution;
  /** Logo stream */
  logo?: InputMaybe<Scalars['Upload']['input']>;
  notAvailablePartnerIds: Array<Scalars['PartnerInstitutionGlobalId']['input']>;
  providerEmail: Scalars['Email']['input'];
  providerName: Scalars['GraphQLShortString']['input'];
  providerUrl: Scalars['Url']['input'];
  serviceId: Scalars['CompanyServiceGlobalId']['input'];
  translations: Array<InputMaybe<CompanyServiceToAdd>>;
};

export type EditCompanyWorkingHoursInput = {
  companyId: Scalars['CompanyGlobalId']['input'];
  days?: InputMaybe<Array<WorkingHoursDayInput>>;
  motherId: Scalars['CompanyGlobalId']['input'];
  outsideWorkingHoursMessage: Scalars['GraphQLMediumString']['input'];
  timezone: Scalars['GraphQLString']['input'];
};

export type EditConfigBrandingInput = {
  configId: Scalars['CompanyConfigGlobalId']['input'];
  isBrandingHidden: Scalars['Boolean']['input'];
};

export type EditConfigColorsInput = {
  configId: Scalars['CompanyConfigGlobalId']['input'];
  darkColor?: InputMaybe<Scalars['Color']['input']>;
  primaryColor?: InputMaybe<Scalars['Color']['input']>;
};

export type EditConfigLogoInput = {
  configId: Scalars['CompanyConfigGlobalId']['input'];
  /** Image stream */
  image?: InputMaybe<Scalars['Upload']['input']>;
  logoType: LogoType;
};

export type EditEmailCompanyVictimAnonymousInput = {
  email?: InputMaybe<Scalars['Email']['input']>;
  identity: Scalars['GraphQLString']['input'];
  organizationalUnitId: Scalars['PublicCompanyGlobalId']['input'];
  passwordPrehash: Scalars['GraphQLString']['input'];
  reportPassword?: InputMaybe<Scalars['GraphQLString']['input']>;
  version: Scalars['EncryptionVersion']['input'];
};

export type EditEmailCompanyVictimInput = {
  email?: InputMaybe<Scalars['Email']['input']>;
};

export type EditFormItemInput = {
  addToCategoriesIds?: InputMaybe<Array<Scalars['CompanyReportCategoryGlobalId']['input']>>;
  formItemId: Scalars['FormItemGlobalId']['input'];
  hint?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['GraphQLShortString']['input']>;
  language: Language;
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditFormItemOptionInput = {
  id?: InputMaybe<Scalars['FormItemOptionGlobalId']['input']>;
  label: Scalars['GraphQLShortString']['input'];
  order: Scalars['Int']['input'];
};

export type EditFuAdminActiveCountryInput = {
  country?: InputMaybe<Country>;
  institution: Institution;
};

export type EditInstitutionAppearanceInput = {
  defaultLanguage?: InputMaybe<Language>;
  /** Image stream */
  image?: InputMaybe<Scalars['Upload']['input']>;
  languages?: InputMaybe<Array<Language>>;
  logoSize?: InputMaybe<LogoSize>;
  motherId: Scalars['CompanyGlobalId']['input'];
  reportFormColor?: InputMaybe<Scalars['Color']['input']>;
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditIsVerifiedCompanyVictimInput = {
  token: Scalars['GraphQLString']['input'];
};

export type EditMemberInput = {
  companyIds: Array<Scalars['CompanyGlobalId']['input']>;
  email?: InputMaybe<Scalars['Email']['input']>;
  memberId: Scalars['UUID']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['AllowedUserName']['input'];
  permissions: Array<PermissionInput>;
  phone?: InputMaybe<Scalars['Phone']['input']>;
};

export type EditMemberNotificationInput = {
  enabled: Scalars['Boolean']['input'];
  notificationId: Scalars['MemberNotificationGlobalId']['input'];
  scope: NotificationScope;
};

export type EditPageInput = {
  content?: InputMaybe<Scalars['GraphQLText']['input']>;
  icon?: InputMaybe<Scalars['GraphQLString']['input']>;
  language: Language;
  motherId: Scalars['CompanyGlobalId']['input'];
  pageId: Scalars['PageGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
  title?: InputMaybe<Scalars['GraphQLShortString']['input']>;
};

export type EditPartnerFormItemInput = {
  addToCategoriesIds?: InputMaybe<Array<Scalars['CompanyReportCategoryGlobalId']['input']>>;
  formItemId: Scalars['FormItemGlobalId']['input'];
  hint?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['GraphQLShortString']['input']>;
  language: Language;
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditPartnerReportCategoryInput = {
  categoryId: Scalars['CompanyReportCategoryGlobalId']['input'];
  configId: Scalars['CompanyConfigGlobalId']['input'];
  language: Language;
  name: Scalars['GraphQLShortString']['input'];
};

export type EditPartnerReportSourceAfterSendInput = {
  emailNotificationEnabled: Scalars['Boolean']['input'];
  language: Language;
  reportSentDescription: Scalars['GraphQLMediumString']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditPartnerReportSourceAnonymityInput = {
  anonymityEnabled: Scalars['Boolean']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditPartnerReportSourceBeforeSendInput = {
  recipientsOnReportForm: RecipientsOnReportForm;
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditPartnerTwoFactorAuthenticationInput = {
  secret?: InputMaybe<Scalars['GraphQLString']['input']>;
  token?: InputMaybe<Scalars['GraphQLString']['input']>;
};

export type EditPartnerUserInput = {
  email: Scalars['Email']['input'];
  memberId: Scalars['UUID']['input'];
};

export type EditReportClosingJustificationInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  reportClosing: ReportClosingType;
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type EditReportJustificationInput = {
  justification: ReportJustification;
  motherId: Scalars['CompanyGlobalId']['input'];
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type EditReportSourceAfterSendInput = {
  emailNotificationEnabled: Scalars['Boolean']['input'];
  language: Language;
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSentDescription: Scalars['GraphQLMediumString']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditReportSourceAnonymityInput = {
  anonymityEnabled: Scalars['Boolean']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditReportSourceBeforeSendInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  recipientsOnReportForm: RecipientsOnReportForm;
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditReportSourceCategoryInput = {
  categoryIds?: InputMaybe<Array<Scalars['CompanyReportCategoryGlobalId']['input']>>;
  hasHint?: InputMaybe<Scalars['Boolean']['input']>;
  hint?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
  label?: InputMaybe<Scalars['GraphQLShortString']['input']>;
  language: Language;
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditReportSourceFormItemTranslationsInput = {
  channelId: Scalars['ReportSourceGlobalId']['input'];
  formItemId: Scalars['FormItemGlobalId']['input'];
  hint?: InputMaybe<Array<HintTranslation>>;
  label: Array<LabelTranslation>;
  motherId: Scalars['CompanyGlobalId']['input'];
  options?: InputMaybe<Array<OptionTranslations>>;
};

export type EditReportSourceNameInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLShortString']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditReportSourceOrganizationalUnitsInput = {
  formItemId: Scalars['FormItemGlobalId']['input'];
  hasHint?: InputMaybe<Scalars['Boolean']['input']>;
  hint?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
  label?: InputMaybe<Scalars['GraphQLShortString']['input']>;
  language: Language;
  motherId: Scalars['CompanyGlobalId']['input'];
  organizationalUnitsIds: Array<Scalars['CompanyGlobalId']['input']>;
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditReportSourcePasswordInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  newPassword: Scalars['GraphQLInstitutionPassword']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditReportSourceShortLinkInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  opaqueShortId: Scalars['GraphQLOpaqueShortId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditSelectFormItemInput = {
  addToCategoriesIds: Array<Scalars['CompanyReportCategoryGlobalId']['input']>;
  formItemId: Scalars['FormItemGlobalId']['input'];
  hint?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
  isRequired: Scalars['Boolean']['input'];
  label: Scalars['GraphQLShortString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  options: Array<EditFormItemOptionInput>;
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditSelfUserInput = {
  bio?: InputMaybe<Scalars['GraphQLShortString']['input']>;
  email: Scalars['Email']['input'];
  language: Language;
  name: Scalars['AllowedUserName']['input'];
  phone: Scalars['GraphQLString']['input'];
};

export type EditSnippetInput = {
  content: Scalars['GraphQLMediumString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLShortString']['input'];
  shortcut: Scalars['GraphQLShortString']['input'];
  snippetId: Scalars['UUID']['input'];
};

export type EditSurveyAfterSendInput = {
  description?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
  title?: InputMaybe<Scalars['GraphQLShortString']['input']>;
};

export type EditSurveyBeforeSendInput = {
  description?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
  title?: InputMaybe<Scalars['GraphQLShortString']['input']>;
};

export type EditSurveyFormItemInput = {
  channelId: Scalars['ReportSourceGlobalId']['input'];
  formItemId: Scalars['FormItemGlobalId']['input'];
  item: SurveyItemEditInput;
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type EditSurveyLogoInput = {
  /** Logo stream */
  logo?: InputMaybe<Scalars['Upload']['input']>;
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type EditSurveySelectFormItemInput = {
  channelId: Scalars['ReportSourceGlobalId']['input'];
  formItemId: Scalars['FormItemGlobalId']['input'];
  item: SurveyItemEditInput;
  motherId: Scalars['CompanyGlobalId']['input'];
  options: Array<EditFormItemOptionInput>;
};

export type EditTwoFactorAuthenticationInput = {
  /** Have to pass mother ID because allowInstitutionPlan need it. */
  motherId: Scalars['CompanyGlobalId']['input'];
  secret?: InputMaybe<Scalars['GraphQLString']['input']>;
  token?: InputMaybe<Scalars['GraphQLString']['input']>;
};

export type EditUserImageInput = {
  /** Image stream */
  image: Scalars['Upload']['input'];
};

export type EditUserPasswordInput = {
  currentPasswordPrehash: Scalars['GraphQLString']['input'];
  isMigration?: InputMaybe<Scalars['Boolean']['input']>;
  newNonce: Scalars['Base64UrlSafe']['input'];
  newPasswordPrehash: Scalars['GraphQLString']['input'];
  newPrivateKeyEncrypted: Scalars['Base64UrlSafe']['input'];
  newSalt: Scalars['Base64UrlSafe']['input'];
  privateKeyRecovery?: InputMaybe<Scalars['Base64UrlSafe']['input']>;
  privateKeyRecoveryNonce?: InputMaybe<Scalars['Base64UrlSafe']['input']>;
  recoveryKeyEncrypted: Scalars['Base64UrlSafe']['input'];
  recoveryKeyEncryptedNonce: Scalars['Base64UrlSafe']['input'];
  systemRecoveryKey?: InputMaybe<Scalars['Base64UrlSafe']['input']>;
};

export type EditWebhookInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLString']['input'];
  types: Array<WebhookType>;
  url: Scalars['Url']['input'];
  webhookId: Scalars['WebhookGlobalId']['input'];
};

export enum EmployeeInvolvement {
  Involved = 'Involved',
  Reporter = 'Reporter',
  Witness = 'Witness'
}

export enum EmployeeStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type EnhanceReportCommentInput = {
  message: Scalars['GraphQLString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  type: MessageAction;
};

export type ExcludeOrganizationalUnitsFromAnonymityInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  organizationalUnitIds: Array<Scalars['CompanyGlobalId']['input']>;
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export enum Feature {
  Api = 'Api',
  Integrations = 'Integrations',
  ReportingForms = 'ReportingForms',
  Snippets = 'Snippets',
  Sso = 'Sso',
  Surveys = 'Surveys',
  TeamMembers = 'TeamMembers',
  Webhooks = 'Webhooks',
  Whitelabel = 'Whitelabel'
}

export enum FeatureType {
  Custom = 'Custom',
  Quantity = 'Quantity',
  Switch = 'Switch'
}

export type ForceDeleteCompanyInput = {
  companyId: Scalars['CompanyGlobalId']['input'];
  shouldForceDelete: Scalars['Boolean']['input'];
};

export type ForgottenUserPasswordInput = {
  email: Scalars['Email']['input'];
};

export enum FormItemType {
  Category = 'Category',
  Classroom = 'Classroom',
  Date = 'Date',
  Email = 'Email',
  MoreInformation = 'MoreInformation',
  MultiSelect = 'MultiSelect',
  MultilineText = 'MultilineText',
  OrganizationalUnit = 'OrganizationalUnit',
  PhoneNumber = 'PhoneNumber',
  Select = 'Select',
  SenderName = 'SenderName',
  SimpleText = 'SimpleText'
}

export enum FuAdminRole {
  Admin = 'Admin',
  Manager = 'Manager',
  Psychologist = 'Psychologist'
}

export type GetBambooHrApiKeyInput = {
  code: Scalars['GraphQLString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  state: Scalars['GraphQLString']['input'];
};

export type GetUkgReadyAccessTokenInput = {
  code: Scalars['GraphQLString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  state: Scalars['GraphQLString']['input'];
};

export type GetVantaAccessTokenInput = {
  code: Scalars['GraphQLString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  state: Scalars['GraphQLString']['input'];
};

export type GetVantaOAuthUrlInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
};

export enum GlobalTable {
  EmailDiscovery = 'EmailDiscovery',
  InternalMaterial = 'InternalMaterial',
  PhoneDiscovery = 'PhoneDiscovery',
  ReportPasswordDiscovery = 'ReportPasswordDiscovery',
  ShortlinkDiscovery = 'ShortlinkDiscovery',
  TokenDiscovery = 'TokenDiscovery'
}

export type GrantCompanyReportAccessInput = {
  memberId: Scalars['UUID']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  recipientKeys: Array<GrantCompanyReportAccessRecipientKeys>;
};

export type GrantCompanyReportAccessRecipientKeys = {
  reportId: Scalars['CompanyReportGlobalId']['input'];
  value: Scalars['Base64UrlSafe']['input'];
};

export type HintTranslation = {
  language: Language;
  translation?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
};

export enum HotlineCountry {
  Ae = 'Ae',
  Ai = 'Ai',
  Ao = 'Ao',
  Ar = 'Ar',
  At = 'At',
  Au = 'Au',
  Ba = 'Ba',
  Bb = 'Bb',
  Be = 'Be',
  Bg = 'Bg',
  Bh = 'Bh',
  Bo = 'Bo',
  Br = 'Br',
  Bs = 'Bs',
  Bw = 'Bw',
  Ca = 'Ca',
  Ch = 'Ch',
  Ci = 'Ci',
  Cl = 'Cl',
  Cn = 'Cn',
  Co = 'Co',
  Cr = 'Cr',
  Cy = 'Cy',
  Cz = 'Cz',
  De = 'De',
  Dk = 'Dk',
  Dm = 'Dm',
  Do = 'Do',
  Ec = 'Ec',
  Ee = 'Ee',
  Eg = 'Eg',
  Es = 'Es',
  Fi = 'Fi',
  Fr = 'Fr',
  Gb = 'Gb',
  Gd = 'Gd',
  Ge = 'Ge',
  Gh = 'Gh',
  Gr = 'Gr',
  Gt = 'Gt',
  Hk = 'Hk',
  Hr = 'Hr',
  Hu = 'Hu',
  Id = 'Id',
  Ie = 'Ie',
  Il = 'Il',
  In = 'In',
  Is = 'Is',
  It = 'It',
  Jm = 'Jm',
  Jo = 'Jo',
  Jp = 'Jp',
  Ke = 'Ke',
  Kg = 'Kg',
  Kh = 'Kh',
  Kr = 'Kr',
  Ky = 'Ky',
  Kz = 'Kz',
  La = 'La',
  Lc = 'Lc',
  Lk = 'Lk',
  Lt = 'Lt',
  Lu = 'Lu',
  Lv = 'Lv',
  Ma = 'Ma',
  Mc = 'Mc',
  Md = 'Md',
  Me = 'Me',
  Mm = 'Mm',
  Mo = 'Mo',
  Mt = 'Mt',
  Mu = 'Mu',
  Mx = 'Mx',
  My = 'My',
  Ng = 'Ng',
  Ni = 'Ni',
  Nl = 'Nl',
  No = 'No',
  Nz = 'Nz',
  Om = 'Om',
  Pa = 'Pa',
  Pe = 'Pe',
  Ph = 'Ph',
  Pk = 'Pk',
  Pl = 'Pl',
  Pr = 'Pr',
  Pt = 'Pt',
  Py = 'Py',
  Qa = 'Qa',
  Ro = 'Ro',
  Rs = 'Rs',
  Ru = 'Ru',
  Sa = 'Sa',
  Se = 'Se',
  Sg = 'Sg',
  Si = 'Si',
  Sk = 'Sk',
  Sv = 'Sv',
  Th = 'Th',
  Tn = 'Tn',
  Tr = 'Tr',
  Tt = 'Tt',
  Tw = 'Tw',
  Tz = 'Tz',
  Ua = 'Ua',
  Ug = 'Ug',
  Us = 'Us',
  Uy = 'Uy',
  Ve = 'Ve',
  Vg = 'Vg',
  Vn = 'Vn',
  Za = 'Za'
}

export enum HotlineLanguage {
  Af = 'Af',
  Ar = 'Ar',
  Bg = 'Bg',
  Bn = 'Bn',
  Ca = 'Ca',
  Cs = 'Cs',
  Cy = 'Cy',
  Da = 'Da',
  De = 'De',
  El = 'El',
  En_Gb = 'En_Gb',
  En_Us = 'En_Us',
  Es = 'Es',
  Es_Mx = 'Es_Mx',
  Eu = 'Eu',
  Fi = 'Fi',
  Fr = 'Fr',
  Fr_Ca = 'Fr_Ca',
  Gu = 'Gu',
  He = 'He',
  Hi = 'Hi',
  Hu = 'Hu',
  Id = 'Id',
  Is = 'Is',
  It = 'It',
  Ja = 'Ja',
  Kn = 'Kn',
  Ko = 'Ko',
  Lv = 'Lv',
  Ml = 'Ml',
  Ms = 'Ms',
  Nb = 'Nb',
  Nl = 'Nl',
  Pa = 'Pa',
  Pl = 'Pl',
  Pt = 'Pt',
  Pt_Pt = 'Pt_Pt',
  Ro = 'Ro',
  Ru = 'Ru',
  Sk = 'Sk',
  Sr = 'Sr',
  Sv = 'Sv',
  Ta = 'Ta',
  Te = 'Te',
  Th = 'Th',
  Tr = 'Tr',
  Uk = 'Uk',
  Vi = 'Vi',
  Zh = 'Zh',
  Zh_Tw = 'Zh_Tw'
}

export enum HotlineOrderStatus {
  Completed = 'Completed',
  Processing = 'Processing'
}

export enum HotlinePhoneNumberType {
  Landline = 'Landline',
  TollFree = 'TollFree'
}

export enum Institution {
  Company = 'Company',
  School = 'School'
}

export type InternalMessageInput = {
  /** attachments stream */
  attachments?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>;
  ciphertext: Scalars['Base64UrlSafe']['input'];
  nonce: Scalars['Base64UrlSafe']['input'];
  originalCommentId: Scalars['CompanyReportInternalCommentGlobalId']['input'];
};

export enum InvitationStatus {
  Active = 'Active',
  Expired = 'Expired',
  Sent = 'Sent'
}

export enum InvoicedTo {
  Client = 'Client',
  Partner = 'Partner'
}

export type LabelTranslation = {
  language: Language;
  translation?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
};

export enum Language {
  Af = 'Af',
  Am = 'Am',
  Ar = 'Ar',
  Az = 'Az',
  Be = 'Be',
  Bg = 'Bg',
  Bn = 'Bn',
  Bs = 'Bs',
  Ca = 'Ca',
  Ceb = 'Ceb',
  Co = 'Co',
  Cs = 'Cs',
  Cy = 'Cy',
  Da = 'Da',
  De = 'De',
  El = 'El',
  En_Gb = 'En_Gb',
  En_Us = 'En_Us',
  Es = 'Es',
  Es_Mx = 'Es_Mx',
  Et = 'Et',
  Eu = 'Eu',
  Fa = 'Fa',
  Fa_Af = 'Fa_Af',
  Fi = 'Fi',
  Fr = 'Fr',
  Fr_Ca = 'Fr_Ca',
  Fy_Nl = 'Fy_Nl',
  Ga = 'Ga',
  Gd = 'Gd',
  Gl = 'Gl',
  Gu = 'Gu',
  Ha = 'Ha',
  Haw = 'Haw',
  He = 'He',
  Hi = 'Hi',
  Hmn = 'Hmn',
  Hr = 'Hr',
  Ht = 'Ht',
  Hu = 'Hu',
  Hy = 'Hy',
  Id = 'Id',
  Ig = 'Ig',
  Is = 'Is',
  It = 'It',
  Ja = 'Ja',
  Jv = 'Jv',
  Ka = 'Ka',
  Kk = 'Kk',
  Km = 'Km',
  Kn = 'Kn',
  Ko = 'Ko',
  Ku = 'Ku',
  Ky = 'Ky',
  Lb = 'Lb',
  Lo = 'Lo',
  Lt = 'Lt',
  Lv = 'Lv',
  Mg = 'Mg',
  Mi = 'Mi',
  Mk = 'Mk',
  Ml = 'Ml',
  Mn = 'Mn',
  Mr = 'Mr',
  Ms = 'Ms',
  Mt = 'Mt',
  My_Mm = 'My_Mm',
  Nb = 'Nb',
  Ne = 'Ne',
  Nl = 'Nl',
  Ny = 'Ny',
  Or = 'Or',
  Pa = 'Pa',
  Pl = 'Pl',
  Ps = 'Ps',
  Pt = 'Pt',
  Pt_Pt = 'Pt_Pt',
  Ro = 'Ro',
  Ru = 'Ru',
  Rw = 'Rw',
  Sd = 'Sd',
  Si = 'Si',
  Sk = 'Sk',
  Sl = 'Sl',
  Sm = 'Sm',
  Sn = 'Sn',
  So = 'So',
  Sq = 'Sq',
  Sr = 'Sr',
  St = 'St',
  Su = 'Su',
  Sv = 'Sv',
  Sw = 'Sw',
  Ta = 'Ta',
  Te = 'Te',
  Tg = 'Tg',
  Th = 'Th',
  Tk = 'Tk',
  Tl = 'Tl',
  Tr = 'Tr',
  Tt = 'Tt',
  Ug = 'Ug',
  Uk = 'Uk',
  Ur = 'Ur',
  Uz = 'Uz',
  Vi = 'Vi',
  Xh = 'Xh',
  Yi = 'Yi',
  Yo = 'Yo',
  Zh = 'Zh',
  Zh_Tw = 'Zh_Tw',
  Zu = 'Zu'
}

export type LinkCompanyToPartnerInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  partnerId: Scalars['PartnerInstitutionGlobalId']['input'];
};

export enum LogoSize {
  lg = 'lg',
  md = 'md',
  xl = 'xl'
}

export enum LogoType {
  Rectangular = 'Rectangular',
  Square = 'Square'
}

export enum MaterialGenerationType {
  Certificate = 'Certificate',
  Leaflet = 'Leaflet',
  Static = 'Static'
}

export enum MaterialType {
  Guide = 'Guide',
  Other = 'Other',
  Promotion = 'Promotion',
  Template = 'Template'
}

export type MemberReportKeys = {
  id?: InputMaybe<Scalars['UUID']['input']>;
  key: Scalars['Base64UrlSafe']['input'];
  restricted?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum MessageAction {
  ExpandMessage = 'ExpandMessage',
  RewriteFriendly = 'RewriteFriendly',
  RewriteProfessional = 'RewriteProfessional',
  SpellCheck = 'SpellCheck'
}

export type MigrateToSSOInput = {
  privateKey: Scalars['Base64UrlSafe']['input'];
  token: Scalars['GraphQLString']['input'];
};

export type MigrateVictimEncryptionInput = {
  currentPasswordPrehash: Scalars['GraphQLString']['input'];
  newNonce: Scalars['Base64UrlSafe']['input'];
  newPasswordPrehash: Scalars['GraphQLString']['input'];
  newPrivateKeyEncrypted: Scalars['Base64UrlSafe']['input'];
  newSalt: Scalars['Base64UrlSafe']['input'];
};

export type NewClosingCommentNonce = {
  body?: InputMaybe<Scalars['Base64UrlSafe']['input']>;
  nonce?: InputMaybe<Scalars['Base64UrlSafe']['input']>;
};

export type NewCompanyReportActivityInput = {
  reportId?: InputMaybe<Scalars['CompanyReportGlobalId']['input']>;
};

export type NewCompanyReportAuditLogInput = {
  reportId?: InputMaybe<Scalars['CompanyReportGlobalId']['input']>;
};

export type NewFormItemOptionInput = {
  label: Scalars['GraphQLShortString']['input'];
  order: Scalars['Int']['input'];
};

export type NewPageOrderType = {
  id: Scalars['PageGlobalId']['input'];
  order: Scalars['Int']['input'];
};

export enum NotificationScope {
  All = 'All',
  OnlyAssignedReports = 'OnlyAssignedReports'
}

export enum NotificationType {
  NewFollowUpCommentForAssignedReport = 'NewFollowUpCommentForAssignedReport',
  NewFollowUpCommentForUnassignedReport = 'NewFollowUpCommentForUnassignedReport',
  NewInternalCommentMention = 'NewInternalCommentMention',
  NewReport = 'NewReport',
  ReportAssignedToMe = 'ReportAssignedToMe',
  ReportNearDeadline = 'ReportNearDeadline'
}

export type OptionTranslation = {
  language: Language;
  translation: Scalars['GraphQLMediumString']['input'];
};

export type OptionTranslations = {
  id: Scalars['FormItemOptionGlobalId']['input'];
  translations: Array<OptionTranslation>;
};

export type OrderByCompanies = {
  key: OrderByCompaniesPossibleKeys;
  order: OrderByKeyword;
};

export enum OrderByCompaniesPossibleKeys {
  CountOfReports = 'CountOfReports'
}

export type OrderByCompanyReports = {
  key: OrderByCompanyReportsPossibleKeys;
  order: OrderByKeyword;
};

export enum OrderByCompanyReportsPossibleKeys {
  category = 'category',
  created_at = 'created_at',
  deadline = 'deadline',
  priority = 'priority',
  source = 'source',
  updated_at = 'updated_at'
}

export enum OrderByKeyword {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type OrderCompanyReportCategoryInput = {
  categories: Array<CompanyReportCategoryOrderType>;
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type OrderPartnerReportCategoryInput = {
  categories: Array<InputMaybe<PartnerReportCategoryOrderType>>;
  configId: Scalars['CompanyConfigGlobalId']['input'];
};

export type PartnerReportCategoryOrderType = {
  id: Scalars['CompanyReportCategoryGlobalId']['input'];
  order: Scalars['Int']['input'];
};

export enum PaymentMethod {
  CreditCard = 'CreditCard',
  Invoice = 'Invoice'
}

export type PermissionAdditionalDataInput = {
  categoryIds?: InputMaybe<Array<InputMaybe<Scalars['CompanyReportCategoryGlobalId']['input']>>>;
};

export type PermissionInput = {
  additionalData?: InputMaybe<PermissionAdditionalDataInput>;
  enabled: Scalars['Boolean']['input'];
  type: PermissionType;
};

export enum PermissionType {
  Analytics = 'Analytics',
  BillingAccess = 'BillingAccess',
  DeleteCases = 'DeleteCases',
  EditCases = 'EditCases',
  ExportCases = 'ExportCases',
  ManageCategories = 'ManageCategories',
  ManageOrganizationalUnits = 'ManageOrganizationalUnits',
  ManageReportingChannels = 'ManageReportingChannels',
  ManageUsers = 'ManageUsers',
  ReportAccess = 'ReportAccess',
  SettingsAccess = 'SettingsAccess',
  Surveys = 'Surveys',
  WriteInternalComments = 'WriteInternalComments',
  WriteMessages = 'WriteMessages'
}

export enum Plan {
  Basic = 'Basic',
  Free = 'Free',
  Premium = 'Premium',
  Standard = 'Standard',
  Unregistered = 'Unregistered'
}

export type ProlongTrialInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
};

export enum RecaptchaType {
  Checkbox = 'Checkbox',
  Invisible = 'Invisible'
}

export enum RecipientsOnReportForm {
  Editable = 'Editable',
  Invisible = 'Invisible',
  Visible = 'Visible'
}

export type RedactedUserInput = {
  id: Scalars['ID']['input'];
};

export type RegisterClientInstitutionInput = {
  clientEmail: Scalars['Email']['input'];
  currency: Currency;
  institution: RegisterInstitution;
  invoicedTo: InvoicedTo;
  plan: Plan;
  registrationReason?: InputMaybe<RegistrationReason>;
  shouldImportPartnerConfig: Scalars['Boolean']['input'];
};

export type RegisterCompanyInput = {
  crypto: RegisterCrypto;
  institution: RegisterInstitution;
  isPurchase?: InputMaybe<Scalars['Boolean']['input']>;
  registrationReason?: InputMaybe<RegistrationReason>;
  user: RegisterUser;
};

export type RegisterCrypto = {
  nonce: Scalars['Base64UrlSafe']['input'];
  passwordPrehash: Scalars['Base64UrlSafe']['input'];
  privateKeyEncrypted: Scalars['Base64UrlSafe']['input'];
  privateKeyRecovery?: InputMaybe<Scalars['Base64UrlSafe']['input']>;
  privateKeyRecoveryNonce?: InputMaybe<Scalars['Base64UrlSafe']['input']>;
  publicKey: Scalars['Base64UrlSafe']['input'];
  recoveryKeyEncrypted: Scalars['Base64UrlSafe']['input'];
  recoveryKeyEncryptedNonce: Scalars['Base64UrlSafe']['input'];
  recoveryKeySystemEncrypted: Scalars['Base64UrlSafe']['input'];
  salt: Scalars['Base64UrlSafe']['input'];
};

export type RegisterInstitution = {
  country: Country;
  name: Scalars['GraphQLShortString']['input'];
  timezone: Scalars['GraphQLString']['input'];
  type: Institution;
};

export type RegisterUser = {
  name: Scalars['AllowedUserName']['input'];
  phone: Scalars['GraphQLString']['input'];
  token: Scalars['GraphQLString']['input'];
};

export enum RegistrationReason {
  Compliance = 'Compliance',
  Hr = 'Hr',
  Other = 'Other',
  Speakup = 'Speakup',
  Survey = 'Survey'
}

export type RejectPartnerRegistrationInput = {
  partnerId: Scalars['PartnerInstitutionGlobalId']['input'];
};

export type RejectSchoolRegistrationInput = {
  companyId: Scalars['CompanyGlobalId']['input'];
};

export type RemovePartnerUserInput = {
  memberId: Scalars['UUID']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type ReportAssignedMember = {
  id: Scalars['UUID']['input'];
  recipientKey: Scalars['Base64UrlSafe']['input'];
};

export type ReportClosingType = {
  newClosingComment?: InputMaybe<NewClosingCommentNonce>;
  newClosingReason?: InputMaybe<ClosingReason>;
};

export enum ReportCreationOrigin {
  Member = 'Member',
  Redaction = 'Redaction',
  Reporter = 'Reporter'
}

export enum ReportDeadlineFilter {
  Later = 'Later',
  Overdue = 'Overdue',
  Soon = 'Soon',
  Today = 'Today'
}

export type ReportFilterInput = {
  categoryIds?: InputMaybe<Array<Scalars['CompanyReportCategoryGlobalId']['input']>>;
  closingReasons?: InputMaybe<Array<ClosingReason>>;
  companyIds?: InputMaybe<Array<Scalars['CompanyGlobalId']['input']>>;
  creationOrigins?: InputMaybe<Array<ReportCreationOrigin>>;
  dateFrom?: InputMaybe<Scalars['DateTime']['input']>;
  dateTo?: InputMaybe<Scalars['DateTime']['input']>;
  deadline?: InputMaybe<ReportDeadlineFilter>;
  employeeIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  justifications?: InputMaybe<Array<ReportJustification>>;
  labelIds?: InputMaybe<Array<Scalars['CompanyReportLabelGlobalId']['input']>>;
  memberIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  priorities?: InputMaybe<Array<ReportPriority>>;
  rating?: InputMaybe<Scalars['GraphQLRating']['input']>;
  reportSourceIds?: InputMaybe<Array<Scalars['ReportSourceGlobalId']['input']>>;
  sources?: InputMaybe<Array<ReportSourceType>>;
  status?: InputMaybe<ReportStatusType>;
  statuses?: InputMaybe<Array<ReportStatusType>>;
  tag?: InputMaybe<Scalars['GraphQLString']['input']>;
};

export enum ReportJustification {
  Justified = 'Justified',
  Unjustified = 'Unjustified',
  Unknown = 'Unknown'
}

export enum ReportPriority {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
  Urgent = 'Urgent'
}

export enum ReportSourceType {
  AutomatedHotline = 'AutomatedHotline',
  Email = 'Email',
  InPerson = 'InPerson',
  LiveHotline = 'LiveHotline',
  Other = 'Other',
  Phone = 'Phone',
  ReportingSystem = 'ReportingSystem',
  Survey = 'Survey'
}

export enum ReportStatusType {
  Archived = 'Archived',
  Closed = 'Closed',
  Open = 'Open'
}

export type RequestCompanyRegistrationInput = {
  companyId: Scalars['PublicCompanyGlobalId']['input'];
  crypto: RegisterCrypto;
  isPurchase?: InputMaybe<Scalars['Boolean']['input']>;
  registrationReason?: InputMaybe<RegistrationReason>;
  user: RegisterUser;
};

export type RequestCompanyReportAccessInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type RequestCompanyServiceInput = {
  email: Scalars['GraphQLString']['input'];
  message: Scalars['GraphQLString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLString']['input'];
  phone: Scalars['GraphQLString']['input'];
  serviceId: Scalars['CompanyServiceGlobalId']['input'];
};

export type RequestLiveHotlineInput = {
  country: Country;
  language: Language;
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLString']['input'];
};

export type RequestNewIntegrationInput = {
  message: Scalars['GraphQLString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type RequestPartnerRegistrationInput = {
  crypto: RegisterCrypto;
  institution: RegisterInstitution;
  registrationReason?: InputMaybe<RegistrationReason>;
  user: RegisterUser;
};

export type ResendMemberInvitationInput = {
  memberId: Scalars['UUID']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type ResendPartnerInvitationInput = {
  memberId: Scalars['UUID']['input'];
};

export type SendCancelSubscriptionRequestInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  reason?: InputMaybe<Scalars['GraphQLString']['input']>;
};

export type SendReportEmailsInput = {
  /** Email addresses to send to the school if not registered */
  emailsForUnregisteredInstitution?: InputMaybe<Array<Scalars['Email']['input']>>;
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type SetHasReportSourceActivePasswordInput = {
  activeReportPassword: Scalars['Boolean']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type SlackWebhookInput = {
  data: Scalars['JSON']['input'];
  token: Scalars['GraphQLString']['input'];
};

export type SurveyItemEditInput = {
  description?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
  isRequired: Scalars['Boolean']['input'];
  maxLength?: InputMaybe<Scalars['GraphQLLongStringLength']['input']>;
  question: Scalars['GraphQLShortString']['input'];
};

export type SurveyItemInput = {
  description?: InputMaybe<Scalars['GraphQLMediumString']['input']>;
  isRequired: Scalars['Boolean']['input'];
  maxLength?: InputMaybe<Scalars['GraphQLLongStringLength']['input']>;
  question: Scalars['GraphQLShortString']['input'];
  type: FormItemType;
};

export type SurveyItemOptionInput = {
  label: Scalars['GraphQLShortString']['input'];
  order: Scalars['Int']['input'];
};

export type SynchronizeEmployeesInput = {
  token: Scalars['GraphQLString']['input'];
};

export type SynchronizeMembersToVantaInput = {
  token: Scalars['GraphQLString']['input'];
};

export type SynchronizeSchoolDiscoveryInput = {
  activeReportPassword: Scalars['Boolean']['input'];
  address: Scalars['GraphQLString']['input'];
  country: Country;
  defaultFormId: Scalars['ReportSourceGlobalId']['input'];
  institutionId: Scalars['CompanyGlobalId']['input'];
  isRegistered: Scalars['Boolean']['input'];
  isTesting: Scalars['Boolean']['input'];
  name: Scalars['GraphQLString']['input'];
  region: Scalars['GraphQLString']['input'];
  shouldDelete: Scalars['Boolean']['input'];
  token: Scalars['GraphQLString']['input'];
};

export type ToggleCompanyE2EEInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  newState: Scalars['Boolean']['input'];
  systemRecoveryKey?: InputMaybe<Scalars['Base64UrlSafe']['input']>;
};

export type ToggleCompanyReportLabelInput = {
  labelId: Scalars['CompanyReportLabelGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type ToggleMemberSlackNotificationsInput = {
  email?: InputMaybe<Scalars['Email']['input']>;
  isEnabled: Scalars['Boolean']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type ToggleOffWorkingHoursInput = {
  companyId: Scalars['CompanyGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
};

export type TogglePartnerMaterialInput = {
  /** False if the material should be shown, true if it should be hidden */
  hide: Scalars['Boolean']['input'];
  partnerId: Scalars['PartnerInstitutionGlobalId']['input'];
  s3Key: Scalars['GraphQLString']['input'];
};

export type TogglePartnerMaterialTypeInput = {
  /** False if the material should be shown, true if it should be hidden */
  hide: Scalars['Boolean']['input'];
  institutionType: Institution;
  language: Language;
  materialType: MaterialType;
  partnerId: Scalars['PartnerInstitutionGlobalId']['input'];
};

export type ToggleReportSourceEnabledInput = {
  isEnabled: Scalars['Boolean']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
};

export type ToggleSurveyActivationInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  reportSourceId: Scalars['ReportSourceGlobalId']['input'];
  status: ChannelStatus;
};

export type ToggleSurveySubmissionVisibilityInput = {
  channelId: Scalars['ReportSourceGlobalId']['input'];
  isVisible: Scalars['Boolean']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  submissionId: Scalars['ID']['input'];
};

export enum TokenType {
  CreatePassword = 'CreatePassword',
  ForgottenPassword = 'ForgottenPassword',
  MigrateFromSSO = 'MigrateFromSSO',
  MigrateToSSO = 'MigrateToSSO',
  VerifyPurchaseEmail = 'VerifyPurchaseEmail',
  VerifyTrialEmail = 'VerifyTrialEmail',
  VerifyVictimEmail = 'VerifyVictimEmail'
}

export enum TranscriptionStatus {
  Completed = 'Completed',
  Failed = 'Failed',
  NotStarted = 'NotStarted',
  Pending = 'Pending',
  TimedOut = 'TimedOut'
}

export type TransferOwnershipInput = {
  institutionId: Scalars['CompanyGlobalId']['input'];
  memberId: Scalars['UUID']['input'];
};

export type TrialProcessMutationInput = {
  token: Scalars['GraphQLString']['input'];
};

export type TruncateGlobalTableInput = {
  table: GlobalTable;
};

export type UnfinishedRegistrationInput = {
  token: Scalars['GraphQLString']['input'];
};

/** Time window during which we query for unfinished registrations. */
export type UnfinishedRegistrationsWindow = {
  endsHoursAgo: Scalars['Int']['input'];
  startsHoursAgo: Scalars['Int']['input'];
};

export type UnlinkCompanyFromPartnerInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  partnerId: Scalars['PartnerInstitutionGlobalId']['input'];
};

export type UpdateCompanyReportLabelInput = {
  labelId: Scalars['CompanyReportLabelGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  name: Scalars['GraphQLString']['input'];
};

export type UpdateReportRatingInput = {
  rating: Scalars['GraphQLRating']['input'];
};

export type UserLoginInput = {
  /** Two factor authentication code */
  code?: InputMaybe<Scalars['GraphQLString']['input']>;
  email: Scalars['Email']['input'];
  /** describe type of user which wants to log in */
  loginType: UserLoginType;
  passwordPrehash: Scalars['GraphQLString']['input'];
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
  version?: InputMaybe<Scalars['EncryptionVersion']['input']>;
};

export enum UserLoginType {
  FuAdmin = 'FuAdmin',
  PublicUser = 'PublicUser',
  Victim = 'Victim'
}

export type UserPreLoginInput = {
  email: Scalars['Email']['input'];
  /** describe type of user which wants to log in */
  loginType: UserLoginType;
};

export type UserSSOLoginInput = {
  email: Scalars['Email']['input'];
};

export type UserTokenPasswordInput = {
  /** Two factor authentication code */
  code?: InputMaybe<Scalars['GraphQLString']['input']>;
  name?: InputMaybe<Scalars['AllowedUserName']['input']>;
  nonce: Scalars['Base64UrlSafe']['input'];
  passwordPrehash: Scalars['Base64UrlSafe']['input'];
  privateKeyEncrypted: Scalars['Base64UrlSafe']['input'];
  privateKeyRecovery?: InputMaybe<Scalars['Base64UrlSafe']['input']>;
  privateKeyRecoveryNonce?: InputMaybe<Scalars['Base64UrlSafe']['input']>;
  publicKey: Scalars['Base64UrlSafe']['input'];
  recoveryKeyEncrypted: Scalars['Base64UrlSafe']['input'];
  recoveryKeyEncryptedNonce: Scalars['Base64UrlSafe']['input'];
  recoveryKeySystemEncrypted?: InputMaybe<Scalars['Base64UrlSafe']['input']>;
  salt: Scalars['Base64UrlSafe']['input'];
  token: Scalars['GraphQLString']['input'];
};

export enum UserTypeType {
  CompanyVictim = 'CompanyVictim',
  FuAdmin = 'FuAdmin',
  Member = 'Member'
}

export enum VerificationStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

export type VerifyApiKeyInput = {
  token: Scalars['GraphQLString']['input'];
};

export type VerifyEmailInput = {
  email: Scalars['Email']['input'];
  isPurchase?: InputMaybe<Scalars['Boolean']['input']>;
  plan?: InputMaybe<Scalars['GraphQLString']['input']>;
  recaptchaType?: InputMaybe<RecaptchaType>;
  token: Scalars['GraphQLString']['input'];
};

export type VerifyMemberSlackNotificationsInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  state: Scalars['GraphQLString']['input'];
};

export type VerifySlackIntegrationInput = {
  code: Scalars['GraphQLString']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  state: Scalars['GraphQLString']['input'];
};

export type VictimLoginInput = {
  identity: Scalars['GraphQLString']['input'];
  organizationalUnitId: Scalars['PublicCompanyGlobalId']['input'];
  passwordPrehash: Scalars['GraphQLString']['input'];
  rememberMe?: InputMaybe<Scalars['Boolean']['input']>;
  reportPassword?: InputMaybe<Scalars['GraphQLString']['input']>;
  version: Scalars['EncryptionVersion']['input'];
};

export type VictimPreLoginInput = {
  identity: Scalars['GraphQLString']['input'];
  organizationalUnitId: Scalars['PublicCompanyGlobalId']['input'];
  reportPassword?: InputMaybe<Scalars['GraphQLString']['input']>;
};

export type VonageWebhookInput = {
  data: Scalars['JSON']['input'];
  token: Scalars['GraphQLString']['input'];
};

export enum WebhookStatus {
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Stopped = 'Stopped'
}

export enum WebhookType {
  InternalCommentCreated = 'InternalCommentCreated',
  MessageCreated = 'MessageCreated',
  ReportCreated = 'ReportCreated'
}

export type WorkingHoursDayInput = {
  from?: InputMaybe<Scalars['WorkingHour']['input']>;
  name: Day;
  to?: InputMaybe<Scalars['WorkingHour']['input']>;
};

export type markAsReadCompanyFollowUpCommentsInput = {
  motherId: Scalars['CompanyGlobalId']['input'];
  reportId: Scalars['CompanyReportGlobalId']['input'];
};

export type FuAdminLayout_admin = { __typename: 'FuAdmin', id: string, name: string | null, role: FuAdminRole | null, companyCountries: Array<Country | null> | null, schoolCountries: Array<Country | null> | null, profileImageUrl: string | null };

type HeaderProfileQuery_FuAdmin = { __typename: 'FuAdmin', id: string, name: string | null, profileImageUrl: string | null };

type HeaderProfileQuery_Member = { __typename: 'Member', id: string, name: string | null, profileImageUrl: string | null };

export type HeaderProfileQuery = HeaderProfileQuery_FuAdmin | HeaderProfileQuery_Member;

export type ApproveAutomatedHotlineOrderMutationVariables = Exact<{
  input: ApproveAutomatedHotlineOrderInput;
}>;


export type ApproveAutomatedHotlineOrderMutation = { approveAutomatedHotlineOrder: { __typename: 'ApproveAutomatedHotlineOrderPayload', success: boolean | null } | null };

export type ApproveLiveHotlineOrderMutationVariables = Exact<{
  input: ApproveLiveHotlineOrderInput;
}>;


export type ApproveLiveHotlineOrderMutation = { approveLiveHotlineOrder: { __typename: 'ApproveLiveHotlineOrderPayload', success: boolean | null } | null };

export type CategoriesModal_config = { __typename: 'CompanyConfig', id: string, reportCategories: Array<{ __typename: 'CompanyReportCategory', id: string, name: string }> };

export type CloseReportMutationVariables = Exact<{
  input: CloseReportInput;
}>;


export type CloseReportMutation = { closeReport: { __typename: 'CloseReportPayload', closedReport: { __typename: 'CompanyReport', id: string, closed: boolean } | null } | null };

export type ForceDeleteInstitutionMutationVariables = Exact<{
  input: ForceDeleteCompanyInput;
}>;


export type ForceDeleteInstitutionMutation = { forceDeleteCompany: { __typename: 'ForceDeleteCompanyPayload', success: boolean | null } | null };

type GrantAccessActionModal_user_FuAdmin = { __typename: 'FuAdmin', id: string, name: string | null, keys: { __typename: 'UserKeys', id: string, publicKey: string | null } };

type GrantAccessActionModal_user_Member = { __typename: 'Member', id: string, name: string | null, keys: { __typename: 'UserKeys', id: string, publicKey: string | null } };

export type GrantAccessActionModal_user = GrantAccessActionModal_user_FuAdmin | GrantAccessActionModal_user_Member;

export type GrantAccessCompanyReportsQueryVariables = Exact<{
  motherId: Scalars['CompanyGlobalId']['input'];
  memberId: Scalars['UUID']['input'];
}>;


export type GrantAccessCompanyReportsQuery = { grantAccessCompanyReports: Array<{ __typename: 'CompanyReport', id: string, encryptionKey: string | null, category: { __typename: 'CompanyReportCategory', id: string, name: string }, company: { __typename: 'Company', id: string, name: string } } | null> };

export type GrantCompanyReportAccessMutationVariables = Exact<{
  input: GrantCompanyReportAccessInput;
}>;


export type GrantCompanyReportAccessMutation = { grantCompanyReportAccess: { __typename: 'GrantCompanyReportAccessPayload', success: boolean | null } | null };

export type LinkCompanyToPartnerModalPartnersQueryVariables = Exact<{ [key: string]: never; }>;


export type LinkCompanyToPartnerModalPartnersQuery = { partners: { __typename: 'PartnerTypeQueryConnection', totalCount: number, items: Array<{ __typename: 'PartnerInstitution', id: string, name: string } | null> | null } | null };

export type linkCompanyToPartnerMutationVariables = Exact<{
  input: LinkCompanyToPartnerInput;
}>;


export type linkCompanyToPartnerMutation = { linkCompanyToPartner: { __typename: 'LinkCompanyToPartnerPayload', success: boolean | null } | null };

export type CompanyMembersQueryVariables = Exact<{
  institutionId: Scalars['CompanyGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
  page: Scalars['Int']['input'];
  rowsPerPage: Scalars['Int']['input'];
}>;


export type CompanyMembersQuery = { institution: { __typename: 'Company', id: string, name: string, config: { __typename: 'CompanyConfig', id: string, reportCategories: Array<{ __typename: 'CompanyReportCategory', id: string, name: string }> }, descendents: Array<{ __typename: 'Company', id: string, name: string }>, organizationalStructure: Array<{ __typename: 'Company', id: string, name: string }> } | null, allCompanyByIdMembers: { __typename: 'AllCompanyByIdMembersConnection', totalCount: number, items: Array<{ __typename: 'Member', id: string, name: string | null, email: string | null, phone: string | null, profileImageUrl: string | null, companyIds: Array<string>, editable: boolean | null, accountType: AccountType, invitation: InvitationStatus, sso: boolean | null, isPartner: boolean, lastLogin: string | null, isAccountOwner: boolean, mother: { __typename: 'Company', id: string, config: { __typename: 'CompanyConfig', id: string, reportCategories: Array<{ __typename: 'CompanyReportCategory', id: string }> }, descendents: Array<{ __typename: 'Company', id: string }> } | null, companies: Array<{ __typename: 'Company', id: string, name: string }>, keys: { __typename: 'UserKeys', id: string, twoFactorAuthentication: boolean | null, publicKey: string | null, permissions: Array<{ __typename: 'Permission', id: string, enabled: boolean, type: PermissionType, additionalData: { __typename: 'PermissionAdditionalData', categoryIds: Array<string> | null, id: string } | null }> } } | null> | null } | null };

export type DeleteMemberMutationVariables = Exact<{
  input: DeleteMemberInput;
}>;


export type DeleteMemberMutation = { deleteMember: { __typename: 'DeleteMemberPayload', success: boolean | null, deletedMemberId: string | null } | null };

export type RemovePartnerUserMutationVariables = Exact<{
  input: RemovePartnerUserInput;
}>;


export type RemovePartnerUserMutation = { removePartnerUser: { __typename: 'RemovePartnerUserPayload', success: boolean | null, deletedMemberId: string | null } | null };

export type ResendMemberInvitationMutationVariables = Exact<{
  input: ResendMemberInvitationInput;
}>;


export type ResendMemberInvitationMutation = { resendMemberInvitation: { __typename: 'ResendMemberInvitationPayload', member: { __typename: 'Member', id: string, invitation: InvitationStatus } | null } | null };

export type SendReportEmailsMutationVariables = Exact<{
  input: SendReportEmailsInput;
}>;


export type SendReportEmailsMutation = { sendReportEmails: { __typename: 'SendReportEmailsPayload', editedReport: { __typename: 'CompanyReport', id: string, emailsSent: boolean, company: { __typename: 'Company', id: string, name: string, country: Country | null, isVerified: boolean | null, isPendingVerification: boolean | null, billing: { __typename: 'Billing', id: string, plan: Plan } | null } } | null } | null };

export type PartnerMembersQueryVariables = Exact<{
  partnerId: Scalars['PartnerInstitutionGlobalId']['input'];
}>;


export type PartnerMembersQuery = { partner: { __typename: 'PartnerInstitution', id: string, members: Array<{ __typename: 'Member', id: string, name: string | null, email: string | null, phone: string | null, profileImageUrl: string | null, invitation: InvitationStatus, lastLogin: string | null }> | null } | null };

export type CreatePaymentUrlMutationVariables = Exact<{
  input: CreatePaymentUrlInput;
}>;


export type CreatePaymentUrlMutation = { createPaymentUrl: { __typename: 'CreatePaymentUrlPayload', url: string | null } | null };

export type PermissionText_permission = { __typename: 'Permission', id: string, type: PermissionType, additionalData: { __typename: 'PermissionAdditionalData', id: string, categoryIds: Array<string> | null } | null };

export type Permissions_member = { __typename: 'Member', id: string, mother: { __typename: 'Company', id: string, config: { __typename: 'CompanyConfig', id: string, reportCategories: Array<{ __typename: 'CompanyReportCategory', id: string, name: string }> } } | null, keys: { __typename: 'UserKeys', id: string, permissions: Array<{ __typename: 'Permission', id: string, type: PermissionType, enabled: boolean, additionalData: { __typename: 'PermissionAdditionalData', id: string, categoryIds: Array<string> | null } | null }> } };

export type ApproveSchoolRegistrationMutationVariables = Exact<{
  input: ApproveSchoolRegistrationInput;
}>;


export type ApproveSchoolRegistrationMutation = { approveSchoolRegistration: { __typename: 'ApproveSchoolRegistrationPayload', company: { __typename: 'Company', id: string, registered: boolean | null, isVerified: boolean | null, billing: { __typename: 'Billing', id: string, plan: Plan } | null } | null } | null };

export type RejectSchoolRegistrationMutationVariables = Exact<{
  input: RejectSchoolRegistrationInput;
}>;


export type RejectSchoolRegistrationMutation = { rejectSchoolRegistration: { __typename: 'RejectSchoolRegistrationPayload', company: { __typename: 'Company', id: string, isVerified: boolean | null, billing: { __typename: 'Billing', id: string, plan: Plan } | null, members: { __typename: 'CompanyMembersConnection', items: Array<{ __typename: 'Member', id: string, name: string | null, email: string | null, phone: string | null } | null> | null } | null } | null } | null };

export type ReportSourcesQueryVariables = Exact<{
  motherId: Scalars['CompanyGlobalId']['input'];
}>;


export type ReportSourcesQuery = { reportSources: { __typename: 'ReportSourcesQueryConnection', items: Array<{ __typename: 'ReportSource', id: string, sourceType: ChannelType, shortLink: string, hasActivePassword: boolean, reportPassword: string | null, name: string, isEnabled: boolean, order: { __typename: 'HotlineOrder', id: string, status: HotlineOrderStatus } | null, config: { __typename: 'AutomatedHotlineReportSourceConfiguration', id: string, phoneNumber: string | null, phoneNumberType: HotlinePhoneNumberType } | { __typename: 'FormReportSourceConfiguration' } | { __typename: 'LiveHotlineReportSourceConfiguration', id: string, phoneNumber: string | null } | { __typename: 'SurveyChannelConfiguration' } | null } | null> | null } | null };

export type ReportSourceHotlineTable_hotline = { __typename: 'ReportSource', id: string, name: string, sourceType: ChannelType, isEnabled: boolean, shortLink: string, order: { __typename: 'HotlineOrder', id: string, status: HotlineOrderStatus } | null, config: { __typename: 'AutomatedHotlineReportSourceConfiguration', id: string, phoneNumber: string | null, phoneNumberType: HotlinePhoneNumberType } | { __typename: 'FormReportSourceConfiguration' } | { __typename: 'LiveHotlineReportSourceConfiguration', id: string, phoneNumber: string | null } | { __typename: 'SurveyChannelConfiguration' } | null };

export type ReportSourcesTable_reportSource = { __typename: 'ReportSource', id: string, sourceType: ChannelType, shortLink: string, hasActivePassword: boolean, reportPassword: string | null };

export type TransferOwnershipModal_member = { __typename: 'Member', id: string, name: string | null };

export type TransferOwnershipMutationVariables = Exact<{
  memberId: Scalars['UUID']['input'];
  institutionId: Scalars['CompanyGlobalId']['input'];
}>;


export type TransferOwnershipMutation = { transferOwnership: { __typename: 'TransferOwnershipPayload', oldOwner: { __typename: 'Member', id: string, accountType: AccountType }, newOwner: { __typename: 'Member', id: string, accountType: AccountType } } | null };

export type unlinkCompanyFromPartnerMutationVariables = Exact<{
  input: UnlinkCompanyFromPartnerInput;
}>;


export type unlinkCompanyFromPartnerMutation = { unlinkCompanyFromPartner: { __typename: 'UnlinkCompanyFromPartnerPayload', success: boolean | null } | null };

export type approvePartnerRegistrationMutation_mutationVariables = Exact<{
  input: ApprovePartnerRegistrationInput;
}>;


export type approvePartnerRegistrationMutation_mutation = { approvePartnerRegistration: { __typename: 'ApprovePartnerRegistrationPayload', partner: { __typename: 'PartnerInstitution', id: string, verificationStatus: VerificationStatus } | null } | null };

export type rejectPartnerRegistrationMutation_mutationVariables = Exact<{
  input: RejectPartnerRegistrationInput;
}>;


export type rejectPartnerRegistrationMutation_mutation = { rejectPartnerRegistration: { __typename: 'RejectPartnerRegistrationPayload', success: boolean | null } | null };

export type deletePartnerMutation_mutationVariables = Exact<{
  input: DeletePartnerInput;
}>;


export type deletePartnerMutation_mutation = { deletePartner: { __typename: 'DeletePartnerPayload', success: boolean | null } | null };

export type FuViewerProvider_admin = { __typename: 'FuAdmin', id: string, name: string | null, role: FuAdminRole | null, companyCountries: Array<Country | null> | null, schoolCountries: Array<Country | null> | null };

export type GeoDataVariables = Exact<{ [key: string]: never; }>;


export type GeoData = { userLocationByGeoIp: { __typename: 'GeoIP', callingCode: string | null, country: Country } | null };

export type AppRouterQueryVariables = Exact<{ [key: string]: never; }>;


export type AppRouterQuery = { viewer: { __typename: 'FuAdmin', id: string, name: string | null, role: FuAdminRole | null, companyCountries: Array<Country | null> | null, schoolCountries: Array<Country | null> | null, profileImageUrl: string | null } | { __typename: 'Member' } | null };

export type CreateDemoCompanyVariables = Exact<{
  input: CreateDemoCompanyInput;
}>;


export type CreateDemoCompany = { createDemoCompany: { __typename: 'CreateDemoCompanyPayload', company: { __typename: 'Company', id: string } | null } | null };

export type AllPartnersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllPartnersQuery = { partners: { __typename: 'PartnerTypeQueryConnection', totalCount: number, items: Array<{ __typename: 'PartnerInstitution', id: string, name: string } | null> | null } | null };

export type CompanyServiceDetailQueryVariables = Exact<{
  serviceId: Scalars['CompanyServiceGlobalId']['input'];
}>;


export type CompanyServiceDetailQuery = { companyService: { __typename: 'CompanyService', id: string, providerName: string | null, countries: Array<Country> | null, providerUrl: string | null, logoUrl: string | null, providerEmail: string | null, defaultLanguage: Language | null, institutionType: Institution | null, notAvailablePartners: Array<{ __typename: 'PartnerInstitution', id: string, name: string } | null> | null, allTranslations: Array<{ __typename: 'CompanyServiceTranslation', id: string, language: Language, default: boolean | null, description: string, categories: Array<string> }> } | null };

export type DeleteCompanyServiceMutationVariables = Exact<{
  input: DeleteCompanyServiceInput;
}>;


export type DeleteCompanyServiceMutation = { deleteCompanyService: { __typename: 'DeleteCompanyServicePayload', success: boolean | null } | null };

export type CompanyServiceEditorFragment = { __typename: 'CompanyService', id: string, providerName: string | null, providerEmail: string | null, providerUrl: string | null, countries: Array<Country> | null, logoUrl: string | null, defaultLanguage: Language | null, institutionType: Institution | null, notAvailablePartners: Array<{ __typename: 'PartnerInstitution', id: string, name: string } | null> | null, allTranslations: Array<{ __typename: 'CompanyServiceTranslation', id: string, language: Language, description: string, categories: Array<string> }> };

export type CompanyServicesQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  rowsPerPage: Scalars['Int']['input'];
  country?: InputMaybe<Country>;
  institutionType?: InputMaybe<Institution>;
}>;


export type CompanyServicesQuery = { companyServices: { __typename: 'CompanyServiceTypes', totalCount: number, items: Array<{ __typename: 'CompanyService', id: string, providerName: string | null, description: string | null, countries: Array<Country> | null, logoUrl: string | null }> } | null };

export type CompanyServicesSupportedCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyServicesSupportedCountriesQuery = { companyServicesSupportedCountries: Array<Country> };

export type AvailablePartnersQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailablePartnersQuery = { partners: { __typename: 'PartnerTypeQueryConnection', totalCount: number, items: Array<{ __typename: 'PartnerInstitution', id: string, name: string } | null> | null } | null };

export type CreateCompanyServiceVariables = Exact<{
  input: CreateCompanyServiceInput;
}>;


export type CreateCompanyService = { createCompanyService: { __typename: 'CreateCompanyServicePayload', service: { __typename: 'CompanyService', id: string } | null } | null };

export type EditCompanyServiceFragment = { __typename: 'CompanyService', id: string, providerName: string | null, providerEmail: string | null, providerUrl: string | null, countries: Array<Country> | null, logoUrl: string | null, defaultLanguage: Language | null, institutionType: Institution | null, notAvailablePartners: Array<{ __typename: 'PartnerInstitution', id: string, name: string } | null> | null, allTranslations: Array<{ __typename: 'CompanyServiceTranslation', id: string, language: Language, description: string, categories: Array<string> }> };

export type EditCompanyServiceVariables = Exact<{
  input: EditCompanyServiceInput;
}>;


export type EditCompanyService = { editCompanyService: { __typename: 'EditCompanyServicePayload', service: { __typename: 'CompanyService', id: string, providerName: string | null, providerEmail: string | null, providerUrl: string | null, countries: Array<Country> | null, logoUrl: string | null, defaultLanguage: Language | null, institutionType: Institution | null, notAvailablePartners: Array<{ __typename: 'PartnerInstitution', id: string, name: string } | null> | null, allTranslations: Array<{ __typename: 'CompanyServiceTranslation', id: string, language: Language, description: string, categories: Array<string> }> } | null } | null };

export type CreateAdminRequestMutationVariables = Exact<{
  input: CreateAdminRequestInput;
}>;


export type CreateAdminRequestMutation = { createAdminRequest: { __typename: 'CreateAdminRequestPayload', success: boolean | null } | null };

export type DashboardQueryVariables = Exact<{
  dateFrom: Scalars['DateTime']['input'];
  dateTo: Scalars['DateTime']['input'];
  country?: InputMaybe<Country>;
  institutionType?: InputMaybe<Institution>;
}>;


export type DashboardQuery = { companyHomepageDashboardStats: { __typename: 'CompanyDashboardStatistics', id: string, reportsCount: number | null, registrationsCount: number | null, pendingRegistrationsCount: number | null } | null };

export type GlobalTablesQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalTablesQuery = { globalTables: Array<GlobalTable> };

export type TruncateGlobalTableMutationVariables = Exact<{
  input: TruncateGlobalTableInput;
}>;


export type TruncateGlobalTableMutation = { truncateGlobalTable: { __typename: 'TruncateGlobalTablePayload', success: boolean } | null };

export type InstitutionQueryVariables = Exact<{
  institutionId: Scalars['CompanyGlobalId']['input'];
  motherId: Scalars['CompanyGlobalId']['input'];
}>;


export type InstitutionQuery = { institution: { __typename: 'Company', id: string, name: string, type: Institution, identificationNumber: string | null, country: Country | null, street: string | null, city: string | null, zipCode: string | null, state: string | null, registered: boolean | null, pdfPassword: string | null, realInstitution: boolean | null, isVerified: boolean | null, createdAt: string | null, billing: { __typename: 'Billing', id: string, subscriptionStart: string | null, subscriptionEnd: string | null, subscriptionStatus: ChargebeeSubscriptionStatus | null, invoicedTo: InvoicedTo, plan: Plan, customerUrl: string | null } | null, partner: { __typename: 'PartnerInstitution', id: string, name: string } | null } | null };

export type InstitutionsTableQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['GraphQLString']['input']>;
  institutionType: Institution;
  orders?: InputMaybe<Array<InputMaybe<OrderByCompanies>>>;
  plan?: InputMaybe<Array<InputMaybe<Plan>>>;
  rowsPerPage: Scalars['Int']['input'];
  country?: InputMaybe<Country>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  memberEmail?: InputMaybe<Scalars['GraphQLString']['input']>;
}>;


export type InstitutionsTableQuery = { companies: { __typename: 'CompanyTypeQueryConnection', totalCount: number, items: Array<{ __typename: 'Company', id: string, parentId: string | null, name: string, country: Country | null, countOfAllCompanyReports: number | null, registered: boolean | null, isPendingVerification: boolean | null, isVerified: boolean | null, createdAt: string | null, billing: { __typename: 'Billing', id: string, subscriptionStatus: ChargebeeSubscriptionStatus | null, plan: Plan } | null } | null> | null } | null };

export type UserPreLoginMutationVariables = Exact<{
  input: UserPreLoginInput;
}>;


export type UserPreLoginMutation = { userPreLogin: { __typename: 'UserPreLoginPayload', version: number | null, salt: string | null } | null };

export type UserLoginMutationVariables = Exact<{
  input: UserLoginInput;
}>;


export type UserLoginMutation = { userLogin: { __typename: 'UserLoginPayload', token: string | null, country: Country | null, userType: UserTypeType | null, publicKey: string | null, privateKey: string | null, nonce: string | null, version: number | null } | null };

export type DeleteInternalMaterialMutationVariables = Exact<{
  input: DeleteInternalMaterialInput;
}>;


export type DeleteInternalMaterialMutation = { deleteInternalMaterial: { __typename: 'DeleteInternalMaterialPayload', success: boolean } | null };

export type MaterialsViewQueryVariables = Exact<{
  institutionType?: InputMaybe<Institution>;
  type?: InputMaybe<MaterialType>;
  language?: InputMaybe<Language>;
}>;


export type MaterialsViewQuery = { materials: Array<{ __typename: 'Material', id: string, type: MaterialType, generatedDocument: MaterialGenerationType, language: Language, institutionType: Institution, name: string, s3Key: string, isFree: boolean, url: string | null }> };

export type CreateInternalMaterialMutationVariables = Exact<{
  input: CreateInternalMaterialInput;
}>;


export type CreateInternalMaterialMutation = { createInternalMaterial: { __typename: 'CreateInternalMaterialPayload', newMaterial: { __typename: 'Material', id: string } } | null };

export type PartnerQueryVariables = Exact<{
  partnerId: Scalars['PartnerInstitutionGlobalId']['input'];
}>;


export type PartnerQuery = { partner: { __typename: 'PartnerInstitution', id: string, name: string, verificationStatus: VerificationStatus, country: Country, chargebeeCustomerUrl: string | null, createdAt: string | null, institutions: Array<{ __typename: 'Company', id: string, name: string }> } | null };

export type PartnersTableQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  rowsPerPage: Scalars['Int']['input'];
  name?: InputMaybe<Scalars['GraphQLString']['input']>;
  country?: InputMaybe<Country>;
  email?: InputMaybe<Scalars['GraphQLString']['input']>;
  waitingForRegistration?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type PartnersTableQuery = { partners: { __typename: 'PartnerTypeQueryConnection', totalCount: number, items: Array<{ __typename: 'PartnerInstitution', id: string, name: string, verificationStatus: VerificationStatus, createdAt: string | null } | null> | null } | null };

export type ReportDetailQueryVariables = Exact<{
  reportId: Scalars['CompanyReportGlobalId']['input'];
}>;


export type ReportDetailQuery = { report: { __typename: 'CompanyReport', id: string, emailsSent: boolean, closed: boolean, createdAt: string | null, answers: Array<{ __typename: 'CaseAnswer', id: string, values: Array<string>, formItem: { __typename: 'FormItem', id: string, type: FormItemType } }>, company: { __typename: 'Company', id: string, name: string, country: Country | null, isVerified: boolean | null, isPendingVerification: boolean | null, billing: { __typename: 'Billing', id: string, plan: Plan } | null } } | null, psychologistReport: { __typename: 'PsychologistReport', id: string, victimName: string | null, moreInfo: string | null } | null };

export type ReportsTableQueryVariables = Exact<{
  page: Scalars['Int']['input'];
  rowsPerPage: Scalars['Int']['input'];
  emailsSent: Scalars['Boolean']['input'];
}>;


export type ReportsTableQuery = { unregisteredCompanyReports: { __typename: 'CompanyReportsAdminQueryConnection', totalCount: number, items: Array<{ __typename: 'CompanyReport', id: string, emailsSent: boolean, closed: boolean, createdAt: string | null } | null> | null } | null, psychologistReports: Array<{ __typename: 'PsychologistReport', id: string, victimName: string | null, moreInfo: string | null } | null> | null };

export type EmailDiscoveryQueryVariables = Exact<{
  email: Scalars['GraphQLString']['input'];
}>;


export type EmailDiscoveryQuery = { region: string | null };

export type AbstractMemberModal_member = { __typename: 'Member', id: string, isAccountOwner: boolean, companyIds: Array<string>, keys: { __typename: 'UserKeys', permissions: Array<{ __typename: 'Permission', type: PermissionType, enabled: boolean, additionalData: { __typename: 'PermissionAdditionalData', categoryIds: Array<string> | null } | null }> } };

export type AbstractMemberModal_institution = { __typename: 'Company', id: string, config: { __typename: 'CompanyConfig', id: string, reportCategories: Array<{ __typename: 'CompanyReportCategory', id: string, name: string }> }, organizationalStructure: Array<{ __typename: 'Company', id: string, name: string }> };

export type CreateMemberModal_member = { __typename: 'Member', id: string, isAccountOwner: boolean, companyIds: Array<string>, companies: Array<{ __typename: 'Company', id: string }>, keys: { __typename: 'UserKeys', id: string, permissions: Array<{ __typename: 'Permission', type: PermissionType, enabled: boolean, additionalData: { __typename: 'PermissionAdditionalData', categoryIds: Array<string> | null } | null }> } };

export type CreateMemberModal_institution = { __typename: 'Company', id: string, config: { __typename: 'CompanyConfig', id: string, reportCategories: Array<{ __typename: 'CompanyReportCategory', id: string, name: string }> }, organizationalStructure: Array<{ __typename: 'Company', id: string, name: string }> };

export type CreateMemberMutationVariables = Exact<{
  input: CreateMemberInput;
  motherId: Scalars['CompanyGlobalId']['input'];
}>;


export type CreateMemberMutation = { createMember: { __typename: 'CreateMemberPayload', createdMember: { __typename: 'Member', id: string, name: string | null, phone: string | null, email: string | null, mother: { __typename: 'Company', id: string, countOfMembers: number } | null } | null } | null };

export type EditMemberModal_member = { __typename: 'Member', id: string, isAccountOwner: boolean, companyIds: Array<string>, keys: { __typename: 'UserKeys', permissions: Array<{ __typename: 'Permission', type: PermissionType, enabled: boolean, additionalData: { __typename: 'PermissionAdditionalData', categoryIds: Array<string> | null } | null }> } };

export type EditMemberModal_memberToEdit = { __typename: 'Member', id: string, isAccountOwner: boolean, name: string | null, phone: string | null, companyIds: Array<string>, email: string | null, mother: { __typename: 'Company', id: string, config: { __typename: 'CompanyConfig', id: string, reportCategories: Array<{ __typename: 'CompanyReportCategory', id: string }> } } | null, keys: { __typename: 'UserKeys', permissions: Array<{ __typename: 'Permission', type: PermissionType, enabled: boolean, additionalData: { __typename: 'PermissionAdditionalData', categoryIds: Array<string> | null } | null }> } };

export type EditMemberModal_institution = { __typename: 'Company', id: string, config: { __typename: 'CompanyConfig', id: string, reportCategories: Array<{ __typename: 'CompanyReportCategory', id: string, name: string }> }, organizationalStructure: Array<{ __typename: 'Company', id: string, name: string }> };

export type EditMemberMutationVariables = Exact<{
  input: EditMemberInput;
  motherId: Scalars['CompanyGlobalId']['input'];
}>;


export type EditMemberMutation = { editMember: { __typename: 'EditMemberPayload', member: { __typename: 'Member', id: string, name: string | null, companyIds: Array<string> } | null } | null };

export type GlobalInfo_member = { __typename: 'Member', id: string, country: Country | null, isPartner: boolean, mother: { __typename: 'Company', id: string, uuid: string, name: string, type: Institution, realInstitution: boolean | null, editable: boolean | null, countOfMembers: number, countOfForms: number, isUkgProEnabled: boolean, isUkgReadyEnabled: boolean, isBambooHrEnabled: boolean, billing: { __typename: 'Billing', id: string, plan: Plan, employees: number | null, subscriptionStatus: ChargebeeSubscriptionStatus | null, entitlements: Array<{ __typename: 'FeatureEntitlement', id: string, featureId: Feature, enabled: boolean, value: string, type: FeatureType }> } | null, config: { __typename: 'CompanyConfig', id: string }, partner: { __typename: 'PartnerInstitution', id: string, config: { __typename: 'CompanyConfig', id: string } | null } | null } | null, keys: { __typename: 'UserKeys', id: string, permissions: Array<{ __typename: 'Permission', id: string, type: PermissionType, enabled: boolean, additionalData: { __typename: 'PermissionAdditionalData', id: string, categoryIds: Array<string> | null } | null }> } };

export type GlobalQueryInfoVariables = Exact<{
  motherId: Scalars['CompanyGlobalId']['input'];
}>;


export type GlobalQueryInfo = { memberViewer: { __typename: 'Member', id: string, legacyId: string | null, country: Country | null, isPartner: boolean, mother: { __typename: 'Company', id: string, uuid: string, name: string, type: Institution, realInstitution: boolean | null, editable: boolean | null, countOfMembers: number, countOfForms: number, isUkgProEnabled: boolean, isUkgReadyEnabled: boolean, isBambooHrEnabled: boolean, billing: { __typename: 'Billing', id: string, plan: Plan, employees: number | null, subscriptionStatus: ChargebeeSubscriptionStatus | null, entitlements: Array<{ __typename: 'FeatureEntitlement', id: string, featureId: Feature, enabled: boolean, value: string, type: FeatureType }> } | null, config: { __typename: 'CompanyConfig', id: string }, partner: { __typename: 'PartnerInstitution', id: string, config: { __typename: 'CompanyConfig', id: string } | null } | null } | null, keys: { __typename: 'UserKeys', id: string, permissions: Array<{ __typename: 'Permission', id: string, type: PermissionType, enabled: boolean, additionalData: { __typename: 'PermissionAdditionalData', id: string, categoryIds: Array<string> | null } | null }> } } | null };

export type PartnerDataQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerDataQuery = { memberViewer: { __typename: 'Member', id: string, partner: { __typename: 'PartnerInstitution', id: string, config: { __typename: 'CompanyConfig', id: string } | null } | null } | null };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { memberViewer: { __typename: 'Member', id: string, legacyId: string | null, isPartner: boolean, language: Language | null, sso: boolean | null, name: string | null, email: string | null, phone: string | null, createdAt: string | null, profileImageUrl: string | null, hasAccessToMultipleInstitutions: boolean, partnerCompanyIds: Array<string>, partner: { __typename: 'PartnerInstitution', id: string, verificationStatus: VerificationStatus, institutions: Array<{ __typename: 'Company', id: string }> } | null, keys: { __typename: 'UserKeys', id: string, privateKey: string | null, publicKey: string | null, partnerPermissions: Array<{ __typename: 'Permission', id: string, type: PermissionType, enabled: boolean, motherId: string }> | null }, motherImplicit: { __typename: 'Company', id: string, name: string, type: Institution, country: Country | null } | null } | null };
