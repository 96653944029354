import { gql, useMutation } from '@apollo/client'
import { ModalConfirm } from '@faceup/ui'
import { notification, useMessage } from '@faceup/ui-base'
import type {
  TransferOwnershipInput,
  TransferOwnershipModal_member,
  TransferOwnershipMutation,
} from '../__generated__/globalTypes'

export const TransferOwnershipFragments = {
  TransferOwnershipModal_member: gql`
    fragment TransferOwnershipModal_member on Member {
      id
      name
    }
  `,
}

const mutations = {
  TransferOwnership: gql`
    mutation TransferOwnershipMutation($memberId: UUID!, $institutionId: CompanyGlobalId!) {
      transferOwnership(input: { memberId: $memberId, institutionId: $institutionId }) {
        oldOwner {
          id
          accountType(motherId: $institutionId)
        }

        newOwner {
          id
          accountType(motherId: $institutionId)
        }
      }
    }
  `,
}
export const TransferOwnershipModal = ({
  member,
  setMember,
  institutionId,
}: {
  member: TransferOwnershipModal_member | null
  setMember: (member: TransferOwnershipModal_member | null) => void
  institutionId: string
}) => {
  const message = useMessage()
  const [transfer, { loading }] = useMutation<TransferOwnershipMutation, TransferOwnershipInput>(
    mutations.TransferOwnership,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL ERROR',
          description: error.message,
        })
      },
      onCompleted: () => {
        message.success('Ownership transferred successfully')
        setMember(null)
      },
    }
  )

  return member ? (
    <ModalConfirm
      title='Transfer ownership'
      opened={!!member}
      onClose={() => setMember(null)}
      onConfirm={async () => {
        transfer({
          variables: {
            memberId: member.id,
            institutionId,
          },
        })
      }}
      confirmState={{ loading }}
    >
      Are you sure you want to transfer ownership to <strong>{member.name}</strong>?
    </ModalConfirm>
  ) : null
}
