import { gql, useQuery } from '@apollo/client'
import {
  Navigate,
  Outlet,
  type RouteObject,
  RouterProvider,
  RoutesProvider,
  ScrollRestoration,
  createBrowserRouter,
} from '@faceup/router'
import { notification } from '@faceup/ui-base'
import { wrapCreateBrowserRouter } from '@sentry/react'
import AdminLayout, { fragments as AdminLayoutFragments } from '../AdminLayout/AdminLayout'
import ViewerProvider, {
  fragments as ViewerProviderFragments,
} from '../Contexts/AdminAccessRightsProvider'
import RouteNotFound from '../RouteNotFound'
import { type AppRouterQuery, FuAdminRole, Institution } from '../__generated__/globalTypes'
import { routes, useRoutes } from '../hooks/useRoutes'
import Auth from '../utils/auth'
import CompanyServiceDetail from './CompanyServices/CompanyServiceDetail'
import CompanyServices from './CompanyServices/CompanyServices'
import CreateAdmin from './CreateAdmin'
import Dashboard from './Dashboard'
import GlobalTables from './GlobalTables'
import InstitutionDetail from './InstitutionDetail'
import Institutions from './Institutions'
import Login from './Login/Login'
import MaterialsView from './MaterialsView'
import PartnerDetail from './PartnerDetail'
import Partners from './Partners'
import ReportDetail from './ReportDetail'
import Reports from './Reports'

export const query = {
  AppRouterQuery: gql`
    query AppRouterQuery {
      viewer {
        ... on FuAdmin {
          id
          ...FuAdminLayout_admin
          ...FuViewerProvider_admin
        }
      }
    }
    ${AdminLayoutFragments.FuAdminLayout_admin}
    ${ViewerProviderFragments.FuViewerProvider_admin}
  `,
}

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

const AppRouter = () => {
  const { client, data } = useQuery<AppRouterQuery>(query.AppRouterQuery, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: async data => {
      // User has invalid JWT
      const viewer = data?.viewer
      if (!viewer?.__typename) {
        logout()
      }
    },
    skip: !Auth.getJwt(),
  })

  const routeLinks = useRoutes()

  const viewer = data?.viewer?.__typename === 'FuAdmin' ? data.viewer : null
  const isAuthenticated = Boolean(viewer) || Boolean(Auth.getJwt())

  const logout = async () => {
    client.stop()
    await client.clearStore()
    Auth.logout()
    window.location.href = '/'
  }

  const unAuthenticatedRoutes: RouteObject[] = [
    {
      index: true,
      Component: Login,
    },
    {
      path: '/create-admin',
      Component: CreateAdmin,
    },
    {
      path: '*',
      element: <Navigate to={routes => routes.home()} />,
    },
  ]

  const authenticatedRoutes: RouteObject[] = [
    {
      path: routes.home,
      element: (
        <AdminLayout logout={logout} viewer={viewer?.__typename === 'FuAdmin' ? viewer : null}>
          <ViewerProvider viewer={viewer}>
            <Outlet />
          </ViewerProvider>
        </AdminLayout>
      ),
      children: [
        {
          path: routes.dashboard,
          Component: Dashboard,
        },
        {
          path: routes.schools,
          element: <Institutions type={Institution.School} />,
        },
        {
          path: routes.companies,
          element: <Institutions type={Institution.Company} />,
        },
        {
          path: routes.institution,
          Component: InstitutionDetail,
        },
        {
          path: routes.services,
          Component: CompanyServices,
        },
        {
          path: routes.service,
          Component: CompanyServiceDetail,
        },
        {
          path: routes.reports,
          Component: Reports,
        },
        {
          path: routes.report,
          Component: ReportDetail,
        },
        {
          path: routes.partners,
          Component: Partners,
        },
        {
          path: routes.partner,
          Component: PartnerDetail,
        },
        {
          path: routes.materials,
          Component: MaterialsView,
        },
        ...(viewer?.role === FuAdminRole.Admin
          ? [
              {
                path: routes.globalTables,
                Component: GlobalTables,
              },
            ]
          : []),
        {
          path: '*',
          Component: RouteNotFound,
        },
      ],
    },
  ]

  const router = sentryCreateBrowserRouter([
    {
      path: '/',
      element: (
        <RoutesProvider routes={routeLinks}>
          <ScrollRestoration />
          <Outlet />
        </RoutesProvider>
      ),
      children: isAuthenticated ? authenticatedRoutes : unAuthenticatedRoutes,
    },
  ])

  return <RouterProvider router={router} />
}

export default AppRouter
