import { gql, useMutation } from '@apollo/client'
import { useNavigate } from '@faceup/router'
import { Button } from '@faceup/ui'
import { Popconfirm, Space, notification, useMessage } from '@faceup/ui-base'
import {
  VerificationStatus,
  type approvePartnerRegistrationMutation_mutation,
  type approvePartnerRegistrationMutation_mutationVariables,
  type deletePartnerMutation_mutation,
  type deletePartnerMutation_mutationVariables,
  type rejectPartnerRegistrationMutation_mutation,
  type rejectPartnerRegistrationMutation_mutationVariables,
} from '../__generated__/globalTypes'

const mutations = {
  approvePartnerRegistrationMutation_mutation: gql`
    mutation approvePartnerRegistrationMutation_mutation($input: ApprovePartnerRegistrationInput!) {
      approvePartnerRegistration(input: $input) {
        partner {
          id
          verificationStatus
        }
      }
    }
  `,
  rejectPartnerRegistrationMutation_mutation: gql`
    mutation rejectPartnerRegistrationMutation_mutation($input: RejectPartnerRegistrationInput!) {
      rejectPartnerRegistration(input: $input) {
        success
      }
    }
  `,
  deletePartnerMutation_mutation: gql`
    mutation deletePartnerMutation_mutation($input: DeletePartnerInput!) {
      deletePartner(input: $input) {
        success
      }
    }
  `,
}

type Props = {
  partnerId: string | null
  verificationStatus: VerificationStatus | null
  disabled?: boolean
}

const VerifyPartnerRegistrationButtons = ({ partnerId, verificationStatus, disabled }: Props) => {
  const navigate = useNavigate()
  const message = useMessage()

  const [rejectPartner, { loading: rejectPartnerLoading }] = useMutation<
    rejectPartnerRegistrationMutation_mutation,
    rejectPartnerRegistrationMutation_mutationVariables
  >(mutations.rejectPartnerRegistrationMutation_mutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: data => {
      if (data.rejectPartnerRegistration?.success) {
        message.success('Partner registration rejected')
        navigate(routes => routes.partners())
      } else {
        message.error('Failed to reject partner registration')
      }
    },
  })
  const [approvePartner, { loading: approvePartnerLoading }] = useMutation<
    approvePartnerRegistrationMutation_mutation,
    approvePartnerRegistrationMutation_mutationVariables
  >(mutations.approvePartnerRegistrationMutation_mutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
  })
  const [deletePartner, { loading: deletePartnerLoading }] = useMutation<
    deletePartnerMutation_mutation,
    deletePartnerMutation_mutationVariables
  >(mutations.deletePartnerMutation_mutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL ERROR',
        description: error.message,
      })
    },
    onCompleted: data => {
      if (data.deletePartner?.success) {
        message.success('Partner deleted')
        navigate(routes => routes.partners())
      } else {
        message.error('Failed to delete partner')
      }
    },
  })

  if (!partnerId) {
    return null
  }

  if (verificationStatus !== VerificationStatus.Pending) {
    return (
      <Popconfirm
        title='Delete partner'
        onConfirm={() => {
          deletePartner({
            variables: {
              input: {
                partnerId,
              },
            },
          })
        }}
      >
        <Button
          disabled={disabled || deletePartnerLoading}
          loading={deletePartnerLoading}
          color='error'
        >
          Delete
        </Button>
      </Popconfirm>
    )
  }

  return (
    <Space>
      <Popconfirm
        title='Reject partner registration'
        onConfirm={() => {
          rejectPartner({
            variables: {
              input: {
                partnerId,
              },
            },
          })
        }}
      >
        <Button disabled={disabled || rejectPartnerLoading} loading={rejectPartnerLoading}>
          Reject
        </Button>
      </Popconfirm>

      <Popconfirm
        title='Approve partner registration'
        onConfirm={() => {
          approvePartner({
            variables: {
              input: {
                partnerId,
              },
            },
          })
        }}
      >
        <Button disabled={disabled || approvePartnerLoading} loading={approvePartnerLoading}>
          Approve
        </Button>
      </Popconfirm>
    </Space>
  )
}

export default VerifyPartnerRegistrationButtons
