import { gql, useQuery } from '@apollo/client'
import { Card } from '@faceup/ui'
import { notification } from '@faceup/ui-base'
import { omitNullInArray } from '@faceup/utils'
import {
  ChannelType,
  type ReportSourcesQuery,
  type ReportSourcesQueryVariables,
} from '../__generated__/globalTypes'
import ReportSourcesHotlineTable, {
  ReportSourceHotlineTable_hotline,
} from './ReportSourcesHotlineTable'
import ReportSourcesTable, { ReportSourcesTable_reportSource } from './ReportSourcesTable'

const query = {
  ReportSourcesQuery: gql`
    query ReportSourcesQuery($motherId: CompanyGlobalId!) {
      reportSources(
        motherId: $motherId
        page: 0
        rowsPerPage: 100
        types: [ReportingSystem, AutomatedHotline, LiveHotline]
      ) {
        items {
          ...ReportSourcesTable_reportSource
          ...ReportSourceHotlineTable_hotline
        }
      }
    }

    ${ReportSourcesTable_reportSource}
    ${ReportSourceHotlineTable_hotline}
  `,
}

type Props = {
  institutionId: string
}

const ReportSourcesCard = ({ institutionId }: Props) => {
  const { data, loading, refetch } = useQuery<ReportSourcesQuery, ReportSourcesQueryVariables>(
    query.ReportSourcesQuery,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL Error',
          description: error.message,
        })
      },
      fetchPolicy: 'cache-and-network',
      variables: {
        motherId: institutionId,
      },
    }
  )

  const reportSources = omitNullInArray(data?.reportSources?.items)

  const groupedReportSources = reportSources?.reduce(
    (acc, item) => ({
      ...acc,
      [item.sourceType]: [...acc[item.sourceType], item],
    }),
    {
      [ChannelType.AutomatedHotline]: [],
      [ChannelType.LiveHotline]: [],
      [ChannelType.ReportingSystem]: [],
      [ChannelType.Survey]: [],
    }
  )

  return (
    <Card>
      <ReportSourcesTable
        reportSources={[
          ...groupedReportSources[ChannelType.ReportingSystem],
          ...groupedReportSources[ChannelType.Survey],
        ]}
        loading={loading}
      />

      <ReportSourcesHotlineTable
        title='Automated Hotline'
        reportSources={groupedReportSources[ChannelType.AutomatedHotline]}
        refetch={refetch}
        loading={loading}
      />
      <ReportSourcesHotlineTable
        title='Live Hotline'
        reportSources={groupedReportSources[ChannelType.LiveHotline]}
        refetch={refetch}
        loading={loading}
      />
    </Card>
  )
}

export default ReportSourcesCard
