import { gql, useMutation, useQuery } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { useLanguageName } from '@faceup/localization'
import { Button, ContentLayout, Form, Paper, TableEnhanced, TableEnhancedCard } from '@faceup/ui'
import { notification, useMessage, useModal } from '@faceup/ui-base'
import { Institution, type Language, MaterialType } from '@faceup/utils'
import { Flex, Group, Select, Stack } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useState } from 'react'
import MoreOptions from '../../Components/MoreOptions'
import type {
  DeleteInternalMaterialMutation,
  DeleteInternalMaterialMutationVariables,
  MaterialsViewQuery,
  MaterialsViewQueryVariables,
} from '../../__generated__/globalTypes'
import { CreateMaterialModal } from './components'

type FilterProps = {
  language: Language | null
  institution: Institution | null
  type: MaterialType | null
}

const mutations = {
  DeleteInternalMaterial: gql`
    mutation DeleteInternalMaterialMutation($input: DeleteInternalMaterialInput!) {
      deleteInternalMaterial(input: $input) {
        success
      }
    }
  `,
}

const query = gql`
  query MaterialsViewQuery(
    $institutionType: Institution
    $type: MaterialType
    $language: Language
  ) {
    materials(institutionType: $institutionType, language: $language, type: $type) {
      id
      type
      generatedDocument
      language
      institutionType
      name
      s3Key
      isFree
      url
    }
  }
`

const MaterialsView = () => {
  const { getLanguageList } = useLanguageName()
  const [isCreateMaterialModalOpened, setIsCreateMaterialModalOpened] = useState(false)
  const message = useMessage()
  const modal = useModal()

  const form = useForm<FilterProps>({})
  const { data, loading, refetch } = useQuery<MaterialsViewQuery, MaterialsViewQueryVariables>(
    query,
    {
      variables: {
        institutionType: form.values.institution,
        language: form.values.language,
        type: form.values.type,
      },
      onError: error => {
        console.error(error)
        notification.error({
          message: 'GQL Error',
          description: error.message,
        })
      },
    }
  )

  const [deleteInternalMaterial] = useMutation<
    DeleteInternalMaterialMutation,
    DeleteInternalMaterialMutationVariables
  >(mutations.DeleteInternalMaterial, {
    onError: error => {
      console.error(error)
      notification.error({
        message: 'GQL Error',
        description: error.message,
      })
    },
    onCompleted: data => {
      if (data.deleteInternalMaterial?.success) {
        message.success(`Material deleted`)
        refetch()
      }
    },
  })

  const materials = data?.materials ?? []

  return (
    <ContentLayout header={<ContentLayout.Header title='Materials' />}>
      <Stack>
        <Paper outline='dark'>
          <Flex m='1rem' justify='space-between' align='flex-end'>
            <Form>
              <Group>
                <Form.Item label='Language'>
                  <Select
                    {...form.getInputProps('language')}
                    searchable
                    data={getLanguageList().map(language => ({
                      label: language.name,
                      value: language.language,
                    }))}
                    clearable
                  />
                </Form.Item>
                <Form.Item label='Institution'>
                  <Select
                    {...form.getInputProps('institution')}
                    data={Object.values(Institution).map(institution => ({
                      label: institution,
                      value: institution,
                    }))}
                    clearable
                  />
                </Form.Item>
                <Form.Item label='Type'>
                  <Select
                    {...form.getInputProps('type')}
                    data={Object.values(MaterialType).map(materialType => ({
                      label: materialType,
                      value: materialType,
                    }))}
                    clearable
                  />
                </Form.Item>
              </Group>
            </Form>
            <Button
              iconBefore={<UntitledIcon icon={ulPlus} />}
              onClick={() => setIsCreateMaterialModalOpened(true)}
            >
              Add material
            </Button>
          </Flex>
        </Paper>
        <TableEnhancedCard>
          <TableEnhanced
            loading={loading}
            columns={[
              {
                title: 'Name',
                dataIndex: 'name',
              },
              {
                title: 'Type',
                dataIndex: 'type',
              },
              {
                title: 'Language',
                dataIndex: 'language',
              },
              {
                title: 'Institution type',
                dataIndex: 'institutionType',
              },
              {
                title: 'S3 key',
                dataIndex: 's3Key',
                render: (s3Key, material) => {
                  return material.url ? <a href={material.url}>{s3Key}</a> : null
                },
              },
              {
                title: 'Is available also to free schools?',
                dataIndex: 'isFree',
                render: isFree => (isFree ? 'Yes' : 'No'),
              },
              {
                title: 'Generated document',
                dataIndex: 'generatedDocument',
                render: generatedDocument => (
                  <>
                    {
                      // conditionally display some icon if isForSchoolDefaultForm / withActivePassword?
                      generatedDocument
                    }
                  </>
                ),
              },
              {
                title: '',
                render: material => (
                  <MoreOptions
                    menu={{
                      items: [
                        {
                          key: 'delete',
                          label: 'Delete',
                          onClick: () => {
                            modal.confirm({
                              title: `Delete internal material`,
                              content: `Do you really want to delete this material? It can't be undone.`,
                              onOk: async () => {
                                await deleteInternalMaterial({
                                  variables: {
                                    input: {
                                      s3Key: material.s3Key,
                                    },
                                  },
                                })
                                await refetch()
                              },
                            })
                          },
                        },
                      ],
                    }}
                  />
                ),
              },
            ]}
            pagination={false}
            dataSource={materials}
          />
        </TableEnhancedCard>
      </Stack>
      {isCreateMaterialModalOpened && (
        <CreateMaterialModal
          selectedLanguage={form.values.language}
          selectedInstitution={form.values.institution}
          selectedType={form.values.type}
          opened
          onClose={() => {
            setIsCreateMaterialModalOpened(false)
          }}
          onSuccess={() => {
            setIsCreateMaterialModalOpened(false)
            refetch()
          }}
        />
      )}
    </ContentLayout>
  )
}

export default MaterialsView
